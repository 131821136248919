export const ALLOW_WISTIA = true;

export const WistiaEditorIFrame = (src, sectionId, clss, element, selector, isEmbedLink) => {
    try {
        const ifrmElement = document.createElement('iframe');
        if (selector) {
            let ele = document.querySelectorAll(selector);
            if (ele && ele.length > 0) {
                ele.forEach((img) => {
                    if (
                        img.src.substring(0, 30) === 'https://player.vimeo.com/video' ||
                        img.src.substring(0, 24) === 'https://fast.wistia.net/'
                    ) {
                        ifrmElement.setAttribute('sectionid', img.getAttribute('sectionid'));
                        ifrmElement.setAttribute('ineditmode', img.getAttribute('ineditmode'));
                        ifrmElement.setAttribute('tabindex', img.getAttribute('tabindex'));
                        ifrmElement.setAttribute('src', img.getAttribute('src'));
                        ifrmElement.setAttribute('alt', img.getAttribute('alt'));
                        ifrmElement.setAttribute('class', img.getAttribute('class'));
                        if (ALLOW_WISTIA) {
                            ifrmElement.classList.add('wistia_embed');
                        }
                        img.parentNode.replaceChild(ifrmElement, img);
                    }
                });
            }
        } else {
            if (src.includes('vimeo.com') && !isEmbedLink) {
                return;
            }
            ifrmElement.setAttribute('sectionid', sectionId);
            ifrmElement.setAttribute('ineditmode', false);
            ifrmElement.setAttribute('src', src);
            ifrmElement.setAttribute('frameborder', 0);
            ifrmElement.setAttribute('class', clss?.trim());
            ifrmElement.classList.add(sectionId?.trim());
            ifrmElement.setAttribute('webkitallowfullscreen', true);
            ifrmElement.setAttribute('mozallowfullscreen', true);
            ifrmElement.setAttribute('allowfullscreen', true);
            if (ALLOW_WISTIA) {
                ifrmElement.classList.add('wistia_embed');
            }
            element?.replaceWith(ifrmElement);
        }
    } catch (error) {
        console.log(error);
    }
};

export const ASSET_TYPE_VIDEO = ALLOW_WISTIA ? 'fast.wistia.net' : 'video';
export const OLD_ASSET_TYPE_VIDEO = 'video';

export const WistiaMigration = () => {
    if (ALLOW_WISTIA) {
        WistiaEmbed(document?.querySelectorAll('iframe.assetseditor:not(.wistia_embed)'));
        WistiaEmbed(document?.querySelectorAll('iframe.assetReplacerClassForImpact:not(.wistia_embed)'));
        WistiaEmbed(document?.querySelectorAll('iframe.assetReplacerClassForFundDescription:not(.wistia_embed)'));
        removeStyleSizeFromWistia();
    }
};

const WistiaEmbed = (element) => {
    element?.forEach((e) => {
        e && e.classList.add('wistia_embed');
    });
};

export const isNotVimeo = (asset, src) => {
    return !(asset == 'video' && typeof src === 'string' && src?.includes('vimeo.com'));
};

export const removeStyleSizeFromWistia = () => {
    document.querySelectorAll('.wistia_embed').forEach((e) => {
        e.style.width = '';
        e.style.height = '';
        e.width = '';
        e.height = '';
    });
};
