// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import {
    userBulkHardDelete,
    userSoftDelete,
    bulkSoftDelete,
    bulkHardDelete,
    siteBulkDeleteMultiple,
    bulkReportDelete,
    userBulkSoftDelete,
    bulkReportSoftDelete,
    siteBulkSoftDelete,
} from '@ovrture/react-redux';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../reducers/actions';
import { useModalContext } from '../../../contexts/ModalContext';

const SurveyApprovalModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    return (
        <>
            <div>
                <p>
                    This recipient and survey is connected to an existing fund name. By approving this survey, response
                    data will be automatically transferred to FY year Impact data for this fund.
                </p>
                <p className="h5">Would you like to approve this survey? </p>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Approve"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        console.log('approve');
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default SurveyApprovalModal;
