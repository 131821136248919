// @flow
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// components
import Table from '../../../components/table/Table';
import { table as TableColumn } from '../../../constants/table';
import config from '../../../config';
import {
    analyticReset,
    userUnpagedList,
    changeLogList,
    changeLogActorList,
    changeLogMediumList,
    changeLogModuleList,
    changeLogComponentList,
    downloadChangeLogVersionFile,
    analyticSessionReset,
    userAdminPlatformList,
} from '@ovrture/react-redux';
import { dateTimeFormat } from '../../../utility';
import {
    getFilterRetainHistory,
    getRetainHistory,
    getTenantOrgId,
    resetRetainHistory,
    setRedirectionId,
} from '../../../helpers/retainHistory';
import { CustomLoader } from '../../../helpers/customLoader';
import { scriptTagHandlerForCommon } from '../../../helpers/editor/migration/TenantMigration';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { getFilterStore } from '../../../helpers/dataFiltering';
import { downloadFileUrl } from '../../../helpers/downloadFile/downloadFile';
import service from '../../../constants/service.constant';

const ChangeLogCommonModule = (props) => {
    const { modalDispatch, modal } = useModalContext();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [allHistoryData, setAllHistoryData] = useState([]);
    const roleList = [
        { label: 'Application Admin', value: 10 },
        { label: 'Report Admin', value: 5 },
        { label: 'Content Admin', value: 6 },
        { label: 'Brand Admin', value: 2 },
        { label: 'Data Admin', value: 7 },
        { label: 'User', value: 4 },
        { label: 'System Admin', value: 1 },
        { label: 'Records', value: 11 },
    ];
    const filterOptions = [
        {
            type: 'select',
            options: [],
            name: 'fkOwnerIdList',
            label: 'Owner(s)',
            isMulti: true,
            multiSelectPlaceholder: 'All',
            isHidden: props?.data?.isPlatformAdmin === 'true' || props?.data?.isPlatformAdmin === true ? true : false,
        },
        {
            type: 'select',
            options: [],
            name: 'fkLoggedInUserIdList',
            label: 'Actor(s)',
            isMulti: true,
            multiSelectPlaceholder: 'All',
            isDisabled: props?.filterDisabled === 'true' ? true : false,
        },
        {
            type: 'select',
            options: roleList,
            name: 'loggedInUserRoleIdList',
            label: 'Role',
            isMulti: true,
            multiSelectPlaceholder: 'All',
            isHidden: props?.data?.isPlatformAdmin === 'true' || props?.data?.isPlatformAdmin === true ? true : false,
        },
        {
            type: 'select',
            options: [
                { label: 'Success', value: 'actionSucceeded' },
                { label: 'Failed', value: 'actionNotSucceeded' },
            ],
            name: 'isActive',
            label: 'Status',
            isMulti: true,
            multiSelectPlaceholder: 'All',
        },
        {
            type: 'select',
            options: [],
            name: 'fkActivityLogMediumIdList',
            label: 'Medium',
            isMulti: true,
            multiSelectPlaceholder: 'All',
        },
        {
            type: 'select',
            options: [],
            name: 'fkActivityLogModuleIdList',
            label: 'Module',
            isMulti: true,
            multiSelectPlaceholder: 'All',
        },
        {
            type: 'select',
            options: [],
            name: 'fkActivityLogComponentIdList',
            label: 'Component',
            isMulti: true,
            multiSelectPlaceholder: 'All',
        },
        {
            type: 'radio',
            name: 'dateRange',
            label: 'Date Range',
            options: [
                { key: 'All', value: 'all' },
                { key: 'Select Date Range', value: 'date' },
            ],
            defaultValue: 'all',
        },
        {
            type: 'date-picker',
            fromLabel: 'Start date',
            toLabel: 'End date',
            from: 'startDate',
            to: 'endDate',
            disabled: true,
        },
    ];

    let {
        listChangeLog,
        usersList,
        me,
        listActor,
        listMedium,
        listModule,
        listComponent,
        exportChangeLogVersion,
        listActorPa,
        validateProfile,
        resetPasswordCommon,
    } = useSelector((state) => ({
        listChangeLog: state?.analytic?.changeLogList?.data,
        listMedium: state?.analytic?.changeLogMediumList?.data,
        listModule: state?.analytic?.changeLogModuleList?.data,
        listComponent: state?.analytic?.changeLogComponentList?.data,
        loading: state?.analytic?.loading,
        usersList: state?.user?.userUnpagedList,
        me: state?.auth?.me,
        listActor: state?.analytic?.changeLogActorList?.data,
        listActorPa: state?.userAdminPlatform?.list?.content,
        exportChangeLogVersion: state?.analyticSession?.downloadChangeLogVersionFile,
        validateProfile: state?.totp?.validateProfile,
        resetPasswordCommon: state?.user?.resetPasswordCommon,
    }));

    const [filterElements, setFilterElements] = useState(filterOptions);
    let [filterValue, setFilterValue] = useState({});
    const [changeLogTableId, setChangeLogTableId] = useState(
        props?.data?.changeLogValue
            ? 'changeLog' + document.location.pathname.replaceAll('/', '_') + '_' + props?.data?.changeLogValue
            : props?.data?.params?.changeLogObj?.value
            ? 'changeLog' +
              document.location.pathname.replaceAll('/', '_') +
              '_' +
              props?.data?.params?.changeLogObj?.value
            : 'changeLog' + document.location.pathname.replaceAll('/', '_')
    );
    const [searchValue, setSearchValue] = useState('');
    const [dataChangeLogList, setDataChangeLogList] = useState([]);
    const [resetSelection, setResetSelection] = useState([]);
    const [uncheckedSelection, setUncheckedSelection] = useState([]);
    const [selectUser, setSelectUser] = useState(id || '');
    const [sortObj, setSortObj] = useState([{ sort: ['colCreatedAt', 'pkActivityLogDetailsId,DESC'] }]);
    const [pageSize, setPageSize] = useState(config.PAGE_SIZE);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [loader, setLoader] = useState(false);
    const [onloadEventListener, setOnloadEventListener] = useState(false);
    const [pageSwitched, setPageSwitched] = useState(null);

    useEffect(() => {
        return () => {
            dispatch(analyticReset());
        };
    }, []);

    useEffect(() => {
        if (exportChangeLogVersion?.code === service.success) {
            downloadFileUrl(exportChangeLogVersion?.data);
            dispatch(analyticSessionReset());
        }
    }, [exportChangeLogVersion]);

    useEffect(() => {
        setRedirectionId(null);
        dispatch(userUnpagedList({ isAnd: true, isUnpaged: true, sortObj: [{ sort: ['colLastName'] }] }));
        dispatch(changeLogMediumList());
        dispatch(changeLogModuleList());
        dispatch(changeLogComponentList());
        if (props?.data?.isPlatformAdmin === true || props?.data?.isPlatformAdmin === 'true') {
            dispatch(userAdminPlatformList({ isAnd: true, isUnpaged: true, sortObj: [{ sort: ['colFirstName'] }] }));
        } else if (me?.organaisationId !== 0) {
            dispatch(changeLogActorList());
        }
        filterElements?.forEach((item) => {
            if (item.type === 'radio') {
                item['handleChange'] = handleChange;
            }
        });
    }, []);

    useEffect(() => {
        scriptTagHandlerForCommon(parseInt(getTenantOrgId()));
    }, []);

    useEffect(() => {
        if (onloadEventListener) {
            setOnloadEventListener(false);
            searchClass(window?.editorAndOutputLib?.toString());
        }
    }, [onloadEventListener]);

    const searchClass = (data) => {
        try {
            let val = data?.search('changeCoverImageOnPageLoad');
            if (val) {
                let v = data.substr(val).search(';');
                const re = /"(.*?)"/g;
                localStorage.setItem('classname', re.exec(data.substr(val, v))[1]);
            }
        } catch (error) {}
    };

    useEffect(() => {
        if (usersList?.content || listActor || listMedium || listModule || listComponent || listActorPa) {
            setOnloadEventListener(true);
            let userName = '';
            filterElements?.forEach((ele) => {
                if (ele.name === 'fkOwnerIdList') {
                    let options = [];
                    usersList?.content?.forEach((user) => {
                        options.push({
                            label: user.colFullName,
                            value: user.id,
                        });
                    });
                    let val = null;
                    ele.options = options;
                    ele.defaultValue = val ? val : userName;
                }
                if (ele.name === 'fkLoggedInUserIdList' && (listActor?.length > 0 || listActorPa?.length > 0)) {
                    let options = [];
                    if (props?.data?.isPlatformAdmin === true || props?.data?.isPlatformAdmin === 'true') {
                        listActorPa?.forEach((user) => {
                            options.push({ label: user.colFullName, value: user.id });
                        });
                        if (props?.name === 'user-activity-log') {
                            let val = null;
                            for (let index = 0; index < listActorPa.length; index++) {
                                const element = listActorPa[index];
                                if (element.id === id || element.id === me.id) {
                                    userName = { label: element.colFullName, value: element.id };
                                    setFilterValue({ fkLoggedInUserIdList: [element.id] });
                                    if (element.id === id || element.id === me.id) break;
                                } else {
                                    userName = { label: me.colFullName, value: me.id };
                                    setFilterValue({ fkLoggedInUserIdList: [me.id] });
                                    break;
                                }
                            }
                            ele.options = options;
                            ele.defaultValue = val ? val : userName;
                        } else {
                            ele.options = options;
                        }
                    } else {
                        listActor?.forEach((user) => {
                            options.push({ label: user.name, value: user.id });
                        });
                        if (props?.name === 'user-activity-log') {
                            let val = null;
                            for (let index = 0; index < listActor?.length; index++) {
                                const element = listActor[index];
                                if (element?.id === id || element?.id === me?.id) {
                                    userName = { label: element.name, value: element.id };
                                    setFilterValue({ fkLoggedInUserIdList: [element.id] });
                                    if (element?.id === id || element?.id === me?.id) break;
                                } else {
                                    userName = { label: me.colFullName, value: me.id };
                                    setFilterValue({ fkLoggedInUserIdList: [me.id] });
                                    break;
                                }
                            }
                            ele.options = options;
                            ele.defaultValue = val ? val : userName;
                        } else {
                            ele.options = options;
                        }
                    }
                }
                if (ele?.name === 'fkActivityLogMediumIdList' && listMedium?.length > 0) {
                    let options = [];
                    listMedium?.forEach((list) => {
                        options.push({ label: list.colMediumName, value: list.pkActivityLogMediumId });
                    });
                    ele.options = options;
                }
                if (ele?.name === 'fkActivityLogModuleIdList' && listModule?.length > 0) {
                    let options = [];
                    listModule?.forEach((list) => {
                        options.push({ label: list.colModuleName, value: list.pkActivityLogModuleId });
                    });
                    ele.options = options;
                }
                if (ele?.name === 'fkActivityLogComponentIdList' && listComponent?.length > 0) {
                    let options = [];
                    listComponent?.forEach((list) => {
                        options.push({ label: list.colComponentName, value: list.pkActivityLogComponentId });
                    });
                    ele.options = options;
                }
            });
            setFilterElements(filterElements);
        }
    }, [usersList, listActor, listMedium, listModule, listComponent, listActorPa]);

    useEffect(() => {
        let historyData = getRetainHistory(changeLogTableId);
        if (historyData) {
            setSortObj(historyData?.sortObj);
            setSearchValue(historyData?.searchText);
            dispatch(changeLogList(historyData));
            setFilterValue(getFilterRetainHistory(changeLogTableId));
        } else {
            resetRetainHistory(changeLogTableId);
            let payload = { page: 0, size: pageSize, isAnd: true, sortObj: sortObj };
            if (props?.data?.params?.changeLogObj)
                payload[props.data.params.changeLogObj.key] = props.data.params.changeLogObj.value;
            else if (props?.data?.changeLogObj) {
                payload[props.data.changeLogObj.key] = props.data.changeLogObj.value;
            } else if (props?.data?.changeLogKey) payload[props.data.changeLogKey] = props.data.changeLogValue;
            if (props?.data?.pageName) {
                payload['fkActivityLogModuleIdList'] = props?.data?.fkActivityLogModuleIdList;
                if (props?.data?.fkActivityLogActionIdList)
                    payload['fkActivityLogActionIdList'] = props?.data?.fkActivityLogActionIdList;
                if (props?.data?.fkActivityLogComponentIdList)
                    payload['fkActivityLogComponentIdList'] = props?.data?.fkActivityLogComponentIdList;
                if (props?.data?.colDataReference2List)
                    payload['colDataReference2List'] = props?.data?.colDataReference2List;
            }
            if (props?.loggedInUserDetails?.id && props?.name === 'user-activity-log') {
                payload['fkLoggedInUserIdList'] = [props?.loggedInUserDetails?.id];
            }
            const filterStore = getFilterStore(changeLogTableId);

            setFilterValue(filterStore);
            dispatch(changeLogList({ ...payload, ...filterStore }));
        }
    }, []);
    useEffect(() => {
        if (validateProfile || resetPasswordCommon) {
            let payload = { page: 0, size: pageSize, isAnd: true, sortObj: sortObj };
            if (props?.data?.params?.changeLogObj)
                payload[props.data.params.changeLogObj.key] = props.data.params.changeLogObj.value;
            else if (props?.data?.changeLogObj) {
                payload[props.data.changeLogObj.key] = props.data.changeLogObj.value;
            } else if (props?.data?.changeLogKey) payload[props.data.changeLogKey] = props.data.changeLogValue;
            if (props?.data?.pageName) {
                payload['fkActivityLogModuleIdList'] = props?.data?.fkActivityLogModuleIdList;
                if (props?.data?.fkActivityLogActionIdList)
                    payload['fkActivityLogActionIdList'] = props?.data?.fkActivityLogActionIdList;
                if (props?.data?.fkActivityLogComponentIdList)
                    payload['fkActivityLogComponentIdList'] = props?.data?.fkActivityLogComponentIdList;
                if (props?.data?.colDataReference2List)
                    payload['colDataReference2List'] = props?.data?.colDataReference2List;
            }
            if (props?.loggedInUserDetails?.id && props?.name === 'user-activity-log') {
                payload['fkLoggedInUserIdList'] = [props?.loggedInUserDetails?.id];
            }
            const filterStore = getFilterStore(changeLogTableId);

            setFilterValue(filterStore);
            dispatch(changeLogList({ ...payload, ...filterStore }));
        }
    }, [validateProfile, resetPasswordCommon]);

    const handleChange = (key, value) => {
        if (key === 'dateRange') {
            filterElements?.forEach((item) => {
                if (item?.type === 'date-picker') {
                    item.disabled = value === 'all' ? true : false;
                }
            });
        }
        setFilterElements([...filterElements]);
    };

    useEffect(() => {
        if (listChangeLog?.content) {
            setOnloadEventListener(true);
            setLoader(false);
            listChangeLog?.content?.forEach((reportItem, index) => {
                listChangeLog.content[index].colCreatedAt = reportItem?.colCreatedAt
                    ? dateTimeFormat(reportItem?.colCreatedAt)
                    : '_';
                listChangeLog.content[index].loggedInUserFullName = reportItem?.loggedInUserFullName ? (
                    <ButtonElement
                        as="text"
                        name={reportItem?.loggedInUserFullName}
                        title={reportItem?.loggedInUserFullName}
                    />
                ) : (
                    '_'
                );
                listChangeLog.content[index].loggedInUserRoleName = reportItem?.loggedInUserRoleName ? (
                    <ButtonElement
                        as="text"
                        name={reportItem?.loggedInUserRoleName}
                        title={reportItem?.loggedInUserRoleName}
                    />
                ) : (
                    '_'
                );
                listChangeLog.content[index].colMediumName = reportItem?.colMediumName ? (
                    <ButtonElement as="text" name={reportItem?.colMediumName} title={reportItem?.colMediumName} />
                ) : (
                    '_'
                );
                listChangeLog.content[index].colActionDetails = reportItem?.colActionDetails ? (
                    <ButtonElement as="text" name={reportItem?.colActionDetails} title={reportItem?.colActionDetails} />
                ) : (
                    '_'
                );
                listChangeLog.content[index].colModuleName = reportItem?.colModuleName ? (
                    <ButtonElement as="text" name={reportItem?.colModuleName} title={reportItem?.colModuleName} />
                ) : (
                    '_'
                );
                listChangeLog.content[index].colComponentName = reportItem?.colComponentName ? (
                    <ButtonElement as="text" name={reportItem?.colComponentName} title={reportItem?.colComponentName} />
                ) : (
                    '_'
                );
                listChangeLog.content[index].colDataReference1 = reportItem?.colDataReference1 ? (
                    <ButtonElement
                        as="text"
                        name={reportItem?.colDataReference1}
                        title={reportItem?.colDataReference1}
                    />
                ) : (
                    '_'
                );
                listChangeLog.content[index].colDataReference2 = reportItem?.colDataReference2 ? (
                    <ButtonElement
                        as="text"
                        name={reportItem?.colDataReference2}
                        title={reportItem?.colDataReference2}
                    />
                ) : (
                    '_'
                );
                listChangeLog.content[index].ownerFullName = reportItem?.ownerFullName ? (
                    <ButtonElement as="text" name={reportItem?.ownerFullName} title={reportItem?.ownerFullName} />
                ) : (
                    '_'
                );
                listChangeLog.content[index].colIsChangesPerformedAvailable =
                    reportItem?.colIsChangesPerformedAvailable === true && reportItem?.versionAvailable === true ? (
                        <>
                            <ButtonElement
                                name="View Details"
                                variant="outline-info"
                                className={'custom-style view-details'}
                                type="button"
                                onClick={() => {
                                    modalDispatch({
                                        type: modalActions.OPEN_MODAL,
                                        payload: {
                                            currentModalId: (Math.random() + 1).toString(36).substring(7),
                                            hasChildModal: true,
                                            modalAction: () => {
                                                modalDispatch({
                                                    type: modalActions.CLOSE_MODAL,
                                                });
                                            },
                                            data: {
                                                activityLogId: reportItem?.pkActivityLogDetailsId,
                                                name: props?.name,
                                                ...props?.data,
                                            },
                                            modalVariant: 'primary',
                                            modalDataKey: ['changeLog', 'changeLogViewDetails'],
                                        },
                                    });
                                }}
                            />
                            {/* <ButtonElement
                                icon={<i className="mdi mdi-cloud-download icon-large" />}
                                className="action-icon hover-primary p-0"
                                type="button"
                                as="href"
                                tooltip="Download"
                                onClick={() => {
                                    dispatch(
                                        downloadChangeLogVersionFile({
                                            activityLogId: reportItem?.pkActivityLogDetailsId,
                                        })
                                    );
                                }}
                            /> */}
                        </>
                    ) : reportItem?.colIsChangesPerformedAvailable === true &&
                      reportItem?.versionAvailable === false ? (
                        <>
                            <ButtonElement
                                name="View Details"
                                variant="outline-info"
                                className={'custom-style view-details'}
                                type="button"
                                onClick={() => {
                                    modalDispatch({
                                        type: modalActions.OPEN_MODAL,
                                        payload: {
                                            currentModalId: (Math.random() + 1).toString(36).substring(7),
                                            hasChildModal: true,
                                            modalAction: () => {
                                                modalDispatch({
                                                    type: modalActions.CLOSE_MODAL,
                                                });
                                            },
                                            data: {
                                                activityLogId: reportItem?.pkActivityLogDetailsId,
                                                name: props?.name,
                                                ...props?.data,
                                            },
                                            modalVariant: 'primary',
                                            modalDataKey: ['changeLog', 'changeLogViewDetails'],
                                        },
                                    });
                                }}
                            />
                        </>
                    ) : reportItem?.colIsChangesPerformedAvailable === false &&
                      reportItem?.versionAvailable === true ? (
                        <>
                            {/* <ButtonElement
                                icon={<i className="mdi mdi-cloud-download icon-large" />}
                                className="action-icon hover-primary p-0"
                                type="button"
                                as="href"
                                tooltip="Download"
                                onClick={() => {
                                    dispatch(
                                        downloadChangeLogVersionFile({
                                            activityLogId: reportItem?.pkActivityLogDetailsId,
                                        })
                                    );
                                }}
                            /> */}
                        </>
                    ) : (
                        '_'
                    );
            });
            setPageSize(listChangeLog?.size);
            setDataChangeLogList(listChangeLog?.content);
        }
    }, [listChangeLog]);

    const updateSelectedValueChangeLogs = (d) => {};

    const getSearchValue = (value) => {
        setAllHistoryData([]);
        setSearchValue(value);
        let payload = {
            isAnd: true,
            page: 0,
            size: pageSize,
            sortObj: sortObj,
            fkOwnerIdList: selectUser,
            ...filterValue,
            searchText: value,
        };
        if (props?.data?.params?.changeLogObj)
            payload[props.data.params.changeLogObj.key] = props.data.params.changeLogObj.value;
        else if (props?.data?.changeLogObj) {
            payload[props.data.changeLogObj.key] = props.data.changeLogObj.value;
        } else if (props?.data?.changeLogKey) payload[props.data.changeLogKey] = props.data.changeLogValue;
        if (props?.data?.pageName) {
            payload['fkActivityLogModuleIdList'] = props?.data?.fkActivityLogModuleIdList;
            if (props?.data?.fkActivityLogActionIdList)
                payload['fkActivityLogActionIdList'] = props?.data?.fkActivityLogActionIdList;
            if (props?.data?.fkActivityLogComponentIdList)
                payload['fkActivityLogComponentIdList'] = props?.data?.fkActivityLogComponentIdList;
            if (props?.data?.colDataReference2List)
                payload['colDataReference2List'] = props?.data?.colDataReference2List;
        }
        if (props?.loggedInUserDetails?.id && props?.name === 'user-activity-log') {
            payload['fkLoggedInUserIdList'] = [props?.loggedInUserDetails?.id];
        }
        dispatch(changeLogList(payload));
    };

    const filterDispatch = (data = '') => {
        setAllHistoryData([]);
        if (data === '') {
            data = {};
        }
        let formData = {
            isAnd: true,
            page: '0',
            size: pageSize,
            searchText: searchValue,
            sortObj: sortObj,
            ...data,
        };
        setResetSelection([]);
        setFilterValue(data);
        const payload = { ...data, ...formData };
        if (props?.data?.pageName) {
            payload['fkActivityLogModuleIdList'] = [props?.data?.fkActivityLogModuleIdList];
            if (props?.data?.fkActivityLogActionIdList)
                payload['fkActivityLogActionIdList'] = props?.data?.fkActivityLogActionIdList;
            if (props?.data?.fkActivityLogComponentIdList)
                payload['fkActivityLogComponentIdList'] = props?.data?.fkActivityLogComponentIdList;
            if (props?.data?.colDataReference2List)
                payload['colDataReference2List'] = props?.data?.colDataReference2List;
        }
        if (props?.loggedInUserDetails?.id && props?.name === 'user-activity-log') {
            payload['fkLoggedInUserIdList'] = [props?.loggedInUserDetails?.id];
        }
        if (props?.data?.params?.changeLogObj)
            payload[props.data.params.changeLogObj.key] = props.data.params.changeLogObj.value;
        else if (props?.data?.changeLogObj) {
            payload[props.data.changeLogObj.key] = props.data.changeLogObj.value;
        } else if (props?.data?.changeLogKey) payload[props.data.changeLogKey] = props.data.changeLogValue;
        dispatch(changeLogList(payload));
    };

    const handleSelectAll = (val) => {};

    useEffect(() => {
        sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'SKIP_LOADER');
        setLoader(true);
    }, []);

    /* Edit column detail */
    const ActionsColumnStatus = ({ row }) => {
        return (
            <>
                <div>
                    <ButtonElement
                        variant="none"
                        tooltip={`${row.original.colIsActionSucceeded ? 'Succeeded' : 'Failed'}`}
                        className={`action-icon ${row.original.colIsActionSucceeded ? 'text-success' : 'text-danger'}`}
                        icon={
                            <i
                                className={`mdi ${
                                    row.original.colIsActionSucceeded
                                        ? 'mdi mdi-moon-full icon-large'
                                        : 'mdi-record-circle icon-large'
                                }`}></i>
                        }
                    />
                </div>
            </>
        );
    };
    return (
        <>
            <Card>
                <CustomLoader loader={loader} />
                <Table
                    tableId={changeLogTableId}
                    columns={TableColumn.changeLog.changeLog([ActionsColumnStatus, props?.name])}
                    data={dataChangeLogList}
                    pageSizes={listChangeLog?.size}
                    pageNumber={listChangeLog?.number}
                    totalElements={listChangeLog?.totalElements}
                    totalPages={listChangeLog?.totalPages}
                    paginationDispatch={changeLogList}
                    sizePerPageList={TableColumn.sizePerPageList(dataChangeLogList)}
                    isSortable={true}
                    pagination={true}
                    isFilter={true}
                    isSearchable={true}
                    isSelectable={false}
                    tableFilter={TableColumn.platFormAdmin.GenericFilter({
                        filterDispatch,
                        formElements: filterElements,
                        tableId: changeLogTableId,
                        customPaginationProps: {
                            isAnd: true,
                            page: listChangeLog?.number,
                            size: listChangeLog?.size,
                            searchText: searchValue,
                            sortObj: sortObj,
                            fkOwnerIdList: selectUser,
                            ...(props?.data?.params?.changeLogObj && {
                                [props.data.params.changeLogObj.key]: props.data.params.changeLogObj.value,
                            }),
                            ...(props?.data?.changeLogObj && {
                                [props.data.changeLogObj.key]: props.data.changeLogObj.value,
                            }),
                            ...(props?.data?.changeLogKey && {
                                [props.data.changeLogKey]: props.data.changeLogValue,
                            }),
                            ...(props?.data?.pageName &&
                                props?.data?.fkActivityLogModuleIdList && {
                                    ['fkActivityLogModuleIdList']: props?.data?.fkActivityLogModuleIdList,
                                }),
                            ...(props?.data?.pageName &&
                                props?.data?.fkActivityLogActionIdList && {
                                    ['fkActivityLogActionIdList']: props?.data?.fkActivityLogActionIdList,
                                }),
                            ...(props?.data?.pageName &&
                                props?.data?.fkActivityLogComponentIdList && {
                                    ['fkActivityLogComponentIdList']: props?.data?.fkActivityLogComponentIdList,
                                }),
                            ...(props?.data?.pageName &&
                                props?.data?.colDataReference2List && {
                                    ['colDataReference2List']: props?.data?.colDataReference2List,
                                }),
                        },
                    })}
                    setSortObj={setSortObj}
                    customPaginationProps={{
                        searchText: searchValue,
                        sortObj: sortObj,
                        ...filterValue,
                        ...(props?.data?.params?.changeLogObj && {
                            [props.data.params.changeLogObj.key]: props.data.params.changeLogObj.value,
                        }),
                        ...(props?.data?.changeLogObj && {
                            [props.data.changeLogObj.key]: props.data.changeLogObj.value,
                        }),
                        ...(props?.data?.changeLogKey && {
                            [props.data.changeLogKey]: props.data.changeLogValue,
                        }),
                        ...(props?.data?.pageName &&
                            props?.data?.fkActivityLogModuleIdList && {
                                ['fkActivityLogModuleIdList']: props?.data?.fkActivityLogModuleIdList,
                            }),
                        ...(props?.data?.pageName &&
                            props?.data?.fkActivityLogActionIdList && {
                                ['fkActivityLogActionIdList']: props?.data?.fkActivityLogActionIdList,
                            }),
                        ...(props?.data?.pageName &&
                            props?.data?.fkActivityLogComponentIdList && {
                                ['fkActivityLogComponentIdList']: props?.data?.fkActivityLogComponentIdList,
                            }),
                        ...(props?.data?.pageName &&
                            props?.data?.colDataReference2List && {
                                ['colDataReference2List']: props?.data?.colDataReference2List,
                            }),
                    }}
                    getSearchValue={getSearchValue}
                    loading={loader}
                    theadClass={TableColumn.table_light}
                    updateSelectedValue={updateSelectedValueChangeLogs}
                    resetSelection={resetSelection}
                    setResetSelection={setResetSelection}
                    uncheckedSelection={uncheckedSelection}
                    tableClass="change-log-table"
                    handleSelectAll={handleSelectAll}
                    isSelectAll={isSelectAll}
                    searchKey={'searchText'}
                    pageSwitched={pageSwitched}
                    setPageSwitched={setPageSwitched}
                />
            </Card>
            {props?.name === 'change-log' && (
                <div className="button-list d-flex justify-content-center">
                    <ButtonElement
                        name="Close"
                        variant="light"
                        type="button"
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            })
                        }
                    />
                </div>
            )}
        </>
    );
};

export default ChangeLogCommonModule;
