// @flow
import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions, notificationActions } from '../../../reducers/actions';
import { useNotificationContex } from '../../../contexts/NotificationContext';
import { recordsReset, recordsCopySiteUrl, recordsCopyReportUrl } from '@ovrture/react-redux';

const EditRecipientsModal = (props) => {
    const { notificationDispatch } = useNotificationContex();
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    let { copySiteUrl, copyReportUrl } = useSelector((state) => ({
        copySiteUrl: state?.records?.copySiteUrl,
        copyReportUrl: state?.records?.copyReportUrl,
    }));
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    // useEffect(() => {
    //     dispatch(recordsReset());
    //     if (props?.data?.params?.type === 'Site') {
    //         sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'SKIP_LOADER');
    //         dispatch(recordsCopySiteUrl({ pkRecordsId: props?.data?.params.recordId }));
    //     } else if (props?.data?.params.type === 'Report') {
    //         dispatch(
    //             recordsCopyReportUrl({
    //                 pkRecordsId: props?.data?.params.recordId,
    //                 reportYear: props?.data?.original?.colFiscalYear,
    //             })
    //         );
    //     }
    // }, []);

    const onSubmit = (data) => {
        //keeping this function for future use
    };

    const handleCopyUrl = (text, msg) => {
        navigator.clipboard.writeText(text);
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
            payload: {
                modalAction: notificationDispatch({
                    type: notificationActions.OPEN_NOTIFICATION,
                    payload: {
                        role: 'success',
                        title: 'Success',
                        iconClass: 'mdi mdi-check-circle h2',
                        className: 'icon',
                        message: msg,
                    },
                }),
            },
        });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <b className="d-block mb-1">First Name</b>
                <Col>
                    <FormInput
                        type="text"
                        id="urlEnabled"
                        className="urlEnabled"
                        register={register}
                        floatinglabel="false"
                        readOnly
                        value={copySiteUrl?.copyURL || copyReportUrl?.copyURL}
                    />
                </Col>
            </Row>
            <Row>
                <b className="d-block mb-1">Last/Program Name *</b>
                <Col>
                    <FormInput
                        type="text"
                        id="urlDisabled"
                        register={register}
                        floatinglabel="false"
                        readOnly
                        value={copySiteUrl?.reviewURL || copyReportUrl?.reviewURL}
                    />
                </Col>
            </Row>
            <Row>
                <b className="d-block mb-1">Recipient Reference</b>
                <Col>
                    <FormInput
                        type="text"
                        id="urlDisabled"
                        register={register}
                        floatinglabel="false"
                        readOnly
                        value={copySiteUrl?.reviewURL || copyReportUrl?.reviewURL}
                    />
                </Col>
            </Row>
            <Row>
                <b className="d-block mb-1">Email Address *</b>
                <Col>
                    <FormInput
                        type="text"
                        id="urlDisabled"
                        register={register}
                        floatinglabel="false"
                        readOnly
                        value={copySiteUrl?.reviewURL || copyReportUrl?.reviewURL}
                    />
                </Col>
            </Row>
            <Row>
                <b className="d-block mb-1">Cell Number</b>
                <Col>
                    <FormInput
                        type="text"
                        id="urlDisabled"
                        register={register}
                        floatinglabel="false"
                        readOnly
                        value={copySiteUrl?.reviewURL || copyReportUrl?.reviewURL}
                    />
                </Col>
            </Row>
            <Col>
                <ButtonElement
                    name="Link Funds"
                    variant="outline-primary"
                    type="button"
                    className="mb-3"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) => {
                                    modalDispatch({
                                        type: modalActions.CLOSE_MODAL,
                                    });
                                },
                                modalVariant: 'primary',
                                modalDataKey: ['common', 'linkFunds'],
                                data: {
                                    // id: record?.data?.pkRecordsId,
                                    // username:
                                    //     record?.data?.colRecordFullName !== null
                                    //         ? record?.data?.colRecordFullName
                                    //         : record?.data?.colRecordLastName,
                                },
                            },
                        })
                    }
                />
            </Col>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Add"
                    variant="primary"
                    type="button"
                    onClick={() =>
                        copySiteUrl?.copyURL || copyReportUrl?.copyURL
                            ? handleCopyUrl(copySiteUrl?.copyURL || copyReportUrl?.copyURL, 'Donor-Facing URL copied.')
                            : modalDispatch({
                                  type: modalActions.CLOSE_MODAL,
                              })
                    }
                />
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default EditRecipientsModal;
