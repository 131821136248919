// @flow
import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { exportDownloadRecord, userList, systemDataExportReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';

const ExportSurveyDataModal = () => {
    const { modalDispatch } = useModalContext();
    const dispatchAction = useDispatch();
    const [user, setUser] = useState('');
    const [toggle, setToggle] = useState(true);
    const { recordExported, users } = useSelector((state) => ({
        recordExported: state?.systemDataExport?.recordExportDownload,
        users: state?.user?.userList,
    }));

    useEffect(() => {
        dispatchAction(
            userList({
                isAnd: true,
                isUnpaged: true,
                sort: 'colLastName,ASC',
            })
        );
    }, []);

    useEffect(() => {
        if (recordExported) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(systemDataExportReset());
        }
    }, [recordExported]);

    const downloadRecord = () => {
        if (user === 'ALL Users') {
            dispatchAction(exportDownloadRecord({}));
        } else {
            dispatchAction(exportDownloadRecord({ userId: user }));
        }
    };

    return (
        <>
            <div className="custom-padding ">
                <p>
                    Download a listing of all recipients, their individual details, and response data for an individual
                    surveys.
                </p>
                <Col md={6} className="mt-3">
                    <FormInput
                        type="select"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        className="custom-select"
                        label="Survey(s)">
                        <option>All Surveys</option>

                        {users?.content.map((usr) => {
                            return (
                                <option key={usr.id} value={usr.id} data-email-id={usr.email}>
                                    {usr.colFullName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
            </div>
            <div className=" d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Export"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle) {
                            setToggle(false);
                            downloadRecord();
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="submit"
                    onClick={() => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    }}
                />
            </div>
        </>
    );
};

export default ExportSurveyDataModal;
