import React, { useEffect, useState } from 'react';
import { Col, Card, Tab, Nav } from 'react-bootstrap';
// components
import { TabNavigation } from '../../../components/form/tabnavigation';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { Prompt } from 'react-router-dom';
import ButtonElement from '../../../components/button/ButtonElement';
import DataSchemaCommonComponent from './DataSchemaCommonComponent';
import { tinymceRemove } from '../../../helpers/editor/tinymce/TinyMceInlineTextEditor';
import { useDispatch } from 'react-redux';
import { dataMapReset } from '@ovrture/react-redux';
import DataSchemaUploader from '../surveyor/DataSchemaUploader';
import { useLocalization } from '@ovrture/react-redux/dist/helpers/dataMap/DataMapLocalizationContext';

export const TABS = {
    USERS: { NAME: 'Users', DATASCOPEID: 1, KEY: 'survey-data-map-users' },
    RECORDS: { NAME: 'Records', DATASCOPEID: 2, KEY: 'survey-data-map-records' },
    FUND_DETAILS: { NAME: 'Fund Details', DATASCOPEID: 3, KEY: 'survey-data-map-fund-details' },
    FY_PERFORMANCE: { NAME: 'FY Performance', DATASCOPEID: 4, KEY: 'survey-data-map-fund-performance' },
    FY_DONORS: { NAME: 'FY Donors', DATASCOPEID: 5, KEY: 'survey-data-map-fund-donor' },
    FY_IMPACT: { NAME: 'FY Impact', DATASCOPEID: 6, KEY: 'survey-data-map-fund-impact' },
};

const DataSchema = (props) => {
    const { surveyStoreClear } = useLocalization();
    const { modalDispatch } = useModalContext();
    const dispatchAction = useDispatch();

    const [tab, setTab] = useState(TABS.USERS.NAME);
    const [haveChange, setHaveChange] = useState(false);

    const handleTabs = (e) => {
        setTimeout(() => {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: () => modalDispatch({ type: modalActions.CLOSE_MODAL }),
                    modalVariant: 'primary',
                    modalDataKey: ['common', 'leavingRouteMsg'],
                    data: {
                        title: 'Apply change before proceeding?',
                        allowTransition: leaveTab,
                        blockTransition: closeModal,
                        tab: e,
                    },
                },
            });
        }, 200);
    };

    const closeModal = () => {
        modalDispatch({ type: modalActions.CLOSE_MODAL });
    };

    const leaveTab = (tb) => {
        setHaveChange(false);
        setTab(tb);
        closeModal();
    };

    useEffect(() => {
        if (tab) {
            dispatchAction(dataMapReset());
            tinymceRemove();
        }
    }, [tab]);

    return (
        <Col md={12} className="data-schema-page">
            <ButtonElement
                icon={<i className={'icon-large mdi change-log-icon ' + process.env.REACT_APP_CHANGE_LOG_ICON}></i>}
                className={
                    process.env.REACT_APP_CHANGE_LOG_HIDE === true || process.env.REACT_APP_CHANGE_LOG_HIDE === 'true'
                        ? 'd-none action-icon hover-primary custom-svg change-log custom-position data-schema-setting dynamic-position-change'
                        : 'action-icon hover-primary custom-svg change-log custom-position data-schema-setting dynamic-position-change'
                }
                tooltip="Change Log"
                onClick={() =>
                    modalDispatch({
                        type: modalActions.OPEN_MODAL,
                        payload: {
                            modalAction: (e) =>
                                modalDispatch({
                                    type: modalActions.CLOSE_MODAL,
                                }),
                            modalVariant: 'primary',
                            modalDataKey: ['changeLog', 'changeLogCommonModule'],
                            data: {
                                fkActivityLogModuleIdList: 43,
                                pageName: 'DataSchema',
                            },
                        },
                    })
                }
            />
            <p>
                Use this module to manipulate the logical structure of how your data is customized within, presented by,
                and integrated with Ovrture.
            </p>
            <Prompt when={haveChange} message={(location) => `Are you sure you want to go to ${location.pathname}`} />
            <Card>
                <Card.Body>
                    <Tab.Container defaultActiveKey={tab}>
                        <Nav variant="pills" activeKey={tab} justify className="p-1 mb-4" defaultActiveKey={tab}>
                            <TabNavigation
                                active={tab === TABS.USERS.NAME}
                                href="#"
                                eventKey={TABS.USERS.NAME}
                                title={TABS.USERS.NAME}
                                icon={<i className="d-md-none d-block me-1 mdi mdi-application-settings"></i>}
                                onClick={() => (haveChange ? handleTabs(TABS.USERS.NAME) : setTab(TABS.USERS.NAME))}
                            />
                            <TabNavigation
                                active={tab === TABS.RECORDS.NAME}
                                href="#"
                                eventKey={TABS.RECORDS.NAME}
                                title={TABS.RECORDS.NAME}
                                icon={<i className="d-md-none d-block me-1 mdi mdi-application-settings"></i>}
                                onClick={() => (haveChange ? handleTabs(TABS.RECORDS.NAME) : setTab(TABS.RECORDS.NAME))}
                            />
                            <TabNavigation
                                active={tab === TABS.FUND_DETAILS.NAME}
                                href="#"
                                eventKey={TABS.FUND_DETAILS.NAME}
                                title={TABS.FUND_DETAILS.NAME}
                                icon={<i className="d-md-none d-block me-1 mdi mdi-content-paste"></i>}
                                onClick={() =>
                                    haveChange ? handleTabs(TABS.FUND_DETAILS.NAME) : setTab(TABS.FUND_DETAILS.NAME)
                                }
                            />
                            <TabNavigation
                                active={tab === TABS.FY_PERFORMANCE.NAME}
                                href="#"
                                eventKey={TABS.FY_PERFORMANCE.NAME}
                                title={TABS.FY_PERFORMANCE.NAME}
                                icon={<i className="d-md-none d-block me-1 mdi mdi-view-headline"></i>}
                                onClick={() =>
                                    haveChange ? handleTabs(TABS.FY_PERFORMANCE.NAME) : setTab(TABS.FY_PERFORMANCE.NAME)
                                }
                            />
                            <TabNavigation
                                active={tab === TABS.FY_DONORS.NAME}
                                href="#"
                                eventKey={TABS.FY_DONORS.NAME}
                                title={TABS.FY_DONORS.NAME}
                                icon={<i className="d-md-none d-block me-1 mdi mdi-tag"></i>}
                                onClick={() =>
                                    haveChange ? handleTabs(TABS.FY_DONORS.NAME) : setTab(TABS.FY_DONORS.NAME)
                                }
                            />
                            <TabNavigation
                                active={tab === TABS.FY_IMPACT.NAME}
                                href="#"
                                eventKey={TABS.FY_IMPACT.NAME}
                                title={TABS.FY_IMPACT.NAME}
                                icon={<i className="d-md-none d-block me-1 mdi mdi-data-matrix"></i>}
                                onClick={() =>
                                    haveChange ? handleTabs(TABS.FY_IMPACT.NAME) : setTab(TABS.FY_IMPACT.NAME)
                                }
                            />
                        </Nav>
                        <Tab.Content>
                            {tab === TABS.USERS.NAME && (
                                <Tab.Pane eventKey={TABS.USERS.NAME} active={tab === TABS.USERS.NAME}>
                                    <DataSchemaCommonComponent
                                        setHaveChange={setHaveChange}
                                        haveChange={haveChange}
                                        tab={TABS.USERS}
                                        setwatchValueField
                                    />
                                </Tab.Pane>
                            )}
                            {tab === TABS.RECORDS.NAME && (
                                <Tab.Pane eventKey={TABS.RECORDS.NAME} active={tab === TABS.RECORDS.NAME}>
                                    <DataSchemaCommonComponent
                                        setHaveChange={setHaveChange}
                                        haveChange={haveChange}
                                        tab={TABS.RECORDS}
                                        setwatchValueField
                                    />
                                </Tab.Pane>
                            )}
                            {tab === TABS.FUND_DETAILS.NAME && (
                                <Tab.Pane eventKey={TABS.FUND_DETAILS.NAME} active={tab === TABS.FUND_DETAILS.NAME}>
                                    <DataSchemaCommonComponent
                                        setHaveChange={setHaveChange}
                                        haveChange={haveChange}
                                        tab={TABS.FUND_DETAILS}
                                    />
                                </Tab.Pane>
                            )}
                            {tab === TABS.FY_PERFORMANCE.NAME && (
                                <Tab.Pane eventKey={TABS.FY_PERFORMANCE.NAME} active={tab === TABS.FY_PERFORMANCE.NAME}>
                                    <DataSchemaCommonComponent
                                        setHaveChange={setHaveChange}
                                        haveChange={haveChange}
                                        tab={TABS.FY_PERFORMANCE}
                                    />
                                </Tab.Pane>
                            )}
                            {tab === TABS.FY_DONORS.NAME && (
                                <Tab.Pane eventKey={TABS.FY_DONORS.NAME} active={tab === TABS.FY_DONORS.NAME}>
                                    <DataSchemaCommonComponent
                                        setHaveChange={setHaveChange}
                                        haveChange={haveChange}
                                        tab={TABS.FY_DONORS}
                                    />
                                </Tab.Pane>
                            )}
                            {tab === TABS.FY_IMPACT.NAME && (
                                <Tab.Pane eventKey={TABS.FY_IMPACT.NAME} active={tab === TABS.FY_IMPACT.NAME}>
                                    <DataSchemaCommonComponent
                                        setHaveChange={setHaveChange}
                                        haveChange={haveChange}
                                        tab={TABS.FY_IMPACT}
                                    />
                                </Tab.Pane>
                            )}
                        </Tab.Content>
                    </Tab.Container>
                </Card.Body>
            </Card>
            {/* <div className="button-list"> */}
            <DataSchemaUploader
                module={{ name: 'Data Map', tab: props?.data?.tab }}
                showButtonName={false}
                showUpload={true}
                showExport={false}
                importSuccess={(isSuccess) => {
                    surveyStoreClear();
                    if (isSuccess && props?.data?.selectedField?.pkDataMapId) {
                        dispatchAction(dataMapReset());
                        dispatchAction(
                            dataMapInfo({
                                pkDataMapId: props?.data?.selectedField?.pkDataMapId,
                            })
                        );
                    }
                }}
            />
            {/* </div> */}
        </Col>
    );
};

export default DataSchema;
