// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Card } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
//Uploader import
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    templateFileDownload,
    templateFileReset,
    crmImportMapping,
    crmValidateMapping,
    crmReset,
    useNotification,
} from '@ovrture/react-redux';
import { downloadFile, downloadFileUrl } from '../../../helpers/downloadFile/downloadFile';
import config from '../../../config';
import service from '../../../constants/service.constant';
import { COMMON_PATH, SYSTEM_ADMIN_PATH } from '../../../routes/role/path';
import { fileValidation } from '../../../helpers/autoSubmit/commonFunctions';
import { addToSessionStorage } from '../../../helpers/editor/migration/TenantMigration';

const CrmMappingUploadModal = (props) => {
    const history = useHistory();
    const [toggle, setToggle] = useState(false);
    const [file, setFile] = useState(null);
    const [Populate, setPopulate] = useState('');
    const { modalDispatch } = useModalContext();
    const search = useLocation().search;
    const pathName = useLocation().pathname;
    const [isImport, setIsImport] = useState(false);
    const [customError, setCustomError] = useState(null);
    const [isImportedStart, setIsImportedStart] = useState(false);
    const [isPreflight, setIsPreflight] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const { Toast } = useNotification();
    const dispatch = useDispatch();
    const isUpdater = new URLSearchParams(search).get('isUpdater') || pathName === '/users-update-import';
    const {
        exportData,
        xlsxData,
        importMapping,
        importMapping_,
        validateMapping,
        socketSuccess,
        socketError,
        userRecordList,
        details,
        loadingList,
        organization,
        pdfResponse,
    } = useSelector((state) => ({
        exportData: state?.templateFile?.download,
        xlsxData: state?.socket?.xlsx,
        importMapping: state?.crm?.crmImportMapping,
        importMapping_: state?.crm?.importMapping,
        validateMapping: state?.crm?.validateMapping,
        socketSuccess: state?.socket?.success,
        socketError: state?.socket?.error,
        userRecordList: state?.user?.userList,
        details: state?.auth?.me,
        loadingList: state?.socket?.loadingList,
        organization: state?.organization?.fetch?.data,
        pdfResponse: state?.socket?.pdfResponse,
    }));

    useEffect(() => {
        switch (props?.data?.currentTab) {
            case 'RECORDS':
                setPopulate('your records');
                break;
            case 'FUND_DETAILS':
                setPopulate('fund details');
                break;
            case 'FUND_PERFORMANCE':
                setPopulate('fund performance');
                break;
            case 'FUND_IMPACT':
                setPopulate('fund impact');
                break;
        }
    }, []);

    useEffect(() => {
        if (pdfResponse) {
            downloadFileUrl(pdfResponse);
        }
    }, [pdfResponse]);

    useEffect(() => {
        // if (exportData) {
        //     downloadFile(
        //         exportData?.data,
        //         'application/zip',
        //         exportData?.headers['content-disposition'].split('filename=')[1]
        //     );
        //     dispatch(templateFileReset());
        // }
        if (xlsxData) {
            if (isPreflight) {
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalVariant: 'primary',
                        modalDataKey: ['common', 'preflightData'],
                        currentModalId: (Math.random() + 1).toString(36).substring(7),
                        hasChildModal: true,
                        data: {
                            file: file,
                            data: xlsxData,
                            handleImport: handleImport,
                            resetForm: resetForm,
                            isUpdater: isUpdater ? isUpdater : false,
                            KeyConponent: 'crmPreflightData',
                            type: props?.data?.currentTab,
                            validationDispatch: {
                                allDispacthes: ['crmValidationErrorDownload'],
                            },
                        },
                    },
                });
            }
            if (isImport) {
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalVariant: 'primary',
                        modalDataKey: ['common', 'importData'],
                        currentModalId: (Math.random() + 1).toString(36).substring(7),
                        hasChildModal: true,
                        data: {
                            //isUpdater: isUpdater || false,
                            file: file,
                            data: xlsxData,
                            resetForm: resetForm,
                            //KeyName: 'importdetail',
                            handleImport: handleImport,
                            //handleDownloadError: handleDownloadError,
                            isUpdater: isUpdater ? isUpdater : false,
                            onRedirect: onRedirect,
                            KeyConponent: 'crmPreflightData',
                            type: props?.data?.currentTab,
                            validationDispatch: {
                                allDispacthes: ['crmValidationErrorDownload'],
                            },
                        },
                    },
                });
            }
        } else if (socketSuccess && xlsxData === null) {
            setTimeout(() => {
                resetForm();
                dispatch(crmReset());
            }, 1000);
        }
    }, [exportData, xlsxData, socketSuccess]);

    useEffect(() => {
        // if (validateMapping) {
        //     //document.querySelector('.CrmMappingUploadModal').parentNode.style.display = 'none';
        //     // dispatch(crmReset());
        // } else if (importMapping) {
        //     // document.querySelector('.CrmMappingUploadModal').parentNode.style.visibility = 'hidden';
        //     // dispatch(crmReset());
        // } else if (importMapping_) {
        //     // document.querySelector('.CrmMappingUploadModal').parentNode.style.visibility = 'hidden';
        //     // dispatch(crmReset());
        // }
    }, [validateMapping, importMapping, importMapping_]);

    useEffect(() => {
        const count = loadingList?.filter((loading) => {
            return (
                loading?.message === 'Preflighting custom column data.' ||
                loading?.message === 'Importing custom column data.'
            );
        });
        setTimeout(() => {
            setDisabled(count?.length > 0);
            sessionStorage.setItem('isProcessing', count?.length > 0);
        }, 100);
    }, [loadingList]);

    const onRedirect = () => {
        history.push(COMMON_PATH.API_CONNECTIONS_MAP_DATA_FIELD);
    };

    const {
        register,
        formState: { errors },
    } = useForm({});

    const downloadTemplate = () => {
        let key = '';
        switch (props?.data?.currentTab) {
            case 'RECORDS':
                key = 'crm-field-mapping-records';
                break;
            case 'FUND_DETAILS':
                key = 'crm-field-mapping-fund-details';
                break;
            case 'FUND_PERFORMANCE':
                key = 'crm-field-mapping-fund-performance';
                break;
            case 'FUND_IMPACT':
                key = 'crm-field-mapping-fund-impact';
                break;
        }
        dispatch(templateFileDownload({ key, isUpdater: isUpdater ? isUpdater : false }));
    };

    const handleShowNotification = () => {
        return Toast.warning({
            description:
                'An preflight/import is currently in progress. once this is complete, you will be able to request a new preflight/import.',
        });
    };

    const handlePreflight = () => {
        try {
            if (disabled && JSON.parse(sessionStorage.getItem('isProcessing'))) {
                handleShowNotification();
                return null;
            }
            const formData = new FormData();
            formData.append('requestFile', file);
            formData.append('type', props?.data?.currentTab);
            formData.append('serviceId', props?.data?.serviceId);
            const payload = {
                //params: { isUpdater: isUpdater ? isUpdater : false },
                data: formData,
            };
            setIsPreflight(true);
            setIsImport(false);
            dispatch(crmValidateMapping(payload));
            let obj = {
                page: 'crmMapping',
                allDispacthes: ['crmValidationErrorDownload'],
                isUpdater: false,
            };
            addToSessionStorage(obj);
            document.querySelector('.CrmMappingUploadModal').parentNode.classList.remove('show');
            document.querySelector('.modal-backdrop').parentNode.classList.remove('show');
        } catch (error) {}
    };

    const handleImport = () => {
        try {
            if (disabled && JSON.parse(sessionStorage.getItem('isProcessing'))) {
                handleShowNotification();
                return null;
            }
            setIsImportedStart(true);
            const formData = new FormData();
            formData.append('requestFile', file);
            formData.append('type', props?.data?.currentTab);
            formData.append('serviceId', props?.data?.serviceId);
            const payload = {
                //params: { isUpdater: isUpdater ? isUpdater : false },
                data: formData,
            };
            setIsPreflight(false);
            setIsImport(true);
            dispatch(crmImportMapping(payload));
            let obj = {
                page: 'crmMapping',
                allDispacthes: ['crmValidationErrorDownload'],
                isUpdater: false,
            };
            addToSessionStorage(obj);
            document.querySelector('.CrmMappingUploadModal').parentNode.classList.remove('show');
            document.querySelector('.modal-backdrop').parentNode.classList.remove('show');
        } catch (error) {}
    };
    const resetForm = () => {
        setToggle(false);
        setFile(null);
        document.getElementById('importForm')?.reset();
    };

    return (
        <>
            <Row className="mt-2 mb-2">
                <Col>
                    <Col>
                        <h5 className="mt-0 text-primary">STEP 1:</h5>
                        <p>Download template:</p>
                        <div className="button-list">
                            <ButtonElement
                                name="Download Excel Template"
                                variant="primary"
                                type="button"
                                onClick={() => downloadTemplate()}
                            />
                        </div>
                    </Col>
                    <Col className="mt-3">
                        <h5 className="text-primary">STEP 2:</h5>
                        <p>Populate all required and relevant fields with your custom field names.</p>
                    </Col>
                    <Col>
                        <h5 className="text-primary">STEP 3:</h5>
                        <p>Locate, select and upload the completed spreadsheet file:</p>
                        <form id="importForm">
                            <FormInput
                                type="file"
                                id="chooseFile"
                                errors={customError}
                                aria-label="Choose file"
                                accept=".xlsx, .xls"
                                register={register}
                                onChange={(e) => {
                                    setCustomError(null);
                                    setFile(e.target.files[0]);
                                    setToggle(e.target.value === '' ? false : true);
                                }}
                            />
                            <div className="button-list mt-3">
                                <ButtonElement
                                    name="Preflight Data"
                                    variant="secondary"
                                    onClick={() =>
                                        fileValidation(file, 'chooseFile', setCustomError) && handlePreflight()
                                    }
                                />
                                <ButtonElement
                                    name="Upload"
                                    variant="primary"
                                    type="button"
                                    onClick={() => fileValidation(file, 'chooseFile', setCustomError) && handleImport()}
                                />
                                <ButtonElement
                                    name="Cancel"
                                    variant="light"
                                    type="button"
                                    onClick={() => props?.hideModal()}
                                />
                            </div>
                        </form>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default CrmMappingUploadModal;
