// @flow
import { Card } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// components
import { FormInput } from '../../../../components/form/input';
import Table from '../../../../components/table/Table';
import ButtonElement from '../../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../../constants/table';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import service from '../../../../constants/service.constant';
import { dateTimeFormat } from '../../../../utility';

import {
    fundTypeList,
    fundTypeSoftDelete,
    fundTypeUpdate,
    fundTypeImport,
    fundTypeReset,
    fundTypeExportDownloadReset,
    templateFileDownload,
    templateFileReset,
    fundTypeBulkSoftDelete,
} from '@ovrture/react-redux';
import { downloadFile, downloadFileUrl } from '../../../../helpers/downloadFile/downloadFile';

/**
 * React component for the Fund Type Modal.
 * This component displays a modal for managing fund types.
 *
 * @component
 * @example
 * return <FundTypeModal />;
 */
const FundTypeModal = (props) => {
    /**
     * State variables
     */
    const [resetSelection, setResetSelection] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [uncheckedSelection, setUncheckedSelection] = useState([]);
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const [data, setData] = useState([]);
    const [updatedAt, setUpdatedAt] = useState();
    const dispatch = useDispatch();
    // const [isSuccess, setIsSuccess] = useState(false);
    const { list, deleted, update, imported, exported, orgFetch, loading, downloaded, pdfResponse } = useSelector(
        (state) => ({
            list: state?.fundType?.list,
            deleted: state?.fundType?.softDelete,
            update: state?.fundType?.update,
            orgFetch: state?.organization?.fetch,
            loading: state?.fundType?.loading,
            imported: state?.fundTypeImport?.import,
            exported: state?.reportDataExport?.fundTypeExportDownload,
            downloaded: state?.templateFile?.download,
            pdfResponse: state?.socket?.pdfResponse,
        })
    );

    /**
     * useEffect hook to format the data and update the updatedAt state variable.
     */
    useEffect(() => {
        if (list?.data) {
            list?.data?.forEach((item) => {
                setUpdatedAt(dateTimeFormat(item?.colUpdatedAt));
                item.id = item?.pkFundTypeId;
            });
        }
    }, [list]);

    useEffect(() => {
        if (pdfResponse) {
            downloadFileUrl(pdfResponse);
        }
    }, [pdfResponse]);

    /**
     * useEffect hook to handle various state updates.
     */
    useEffect(() => {
        if (list?.data) setData(list.data);
        if (deleted) {
            deleted.code === service.accepted &&
                dispatch(fundTypeList({ orgId: orgFetch?.data?.id, sort: ['colFundTypeName'] }));
        }
        if (update) {
            dispatch(fundTypeReset());
            dispatch(fundTypeList({ orgId: orgFetch?.data?.id, sort: ['colFundTypeName'] }));
        }
        if (imported?.code === service.bulkUpdated) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            reset();
        }
        if (exported) {
            downloadFile(
                exported?.data,
                'application/zip',
                exported?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(fundTypeExportDownloadReset());
        }
        // if (downloaded) {
        //     downloadFile(
        //         downloaded?.data,
        //         'application/zip',
        //         downloaded?.headers['content-disposition'].split('filename=')[1]
        //     );
        //     dispatch(templateFileReset());
        // }
    }, [list, deleted, update, imported, exported, downloaded]);

    useEffect(() => {
        if (props?.data?.isSuccess === true) {
            dispatch(fundTypeReset());
            dispatch(fundTypeList({ orgId: orgFetch?.data?.id, sort: ['colFundTypeName'] }));
        }
    }, [props?.data?.isSuccess]);

    /**
     * useForm hook for handling form inputs and submission.
     */
    const { register, handleSubmit, reset } = useForm();

    /**
     * Handles the upload of data.
     *
     * @param {Object} data - The data to be uploaded.
     */
    const onUpload = (data) => {
        dispatch(
            fundTypeImport(
                { orgId: orgFetch?.data?.id },
                {
                    requestFile: data?.chooseFile[0],
                }
            )
        );
    };

    /**
     * React component for rendering the Fund Types input field.
     *
     * @param {Object} row - The row data for the input field.
     * @returns {JSX.Element} - The Fund Types input field.
     */
    const FundTypes = ({ row }) => {
        return (
            <FormInput
                type="text"
                label="Type"
                className="mb-0"
                maxLength="75"
                id={'fundType' + row.id}
                defaultValue={row.original.colFundTypeName}
                onKeyUp={(e) => (row.original.colFundTypeName = e.target.value)}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };

    /**
     * React component for rendering the Uploader Fund Code input field.
     *
     * @param {Object} row - The row data for the input field.
     * @returns {JSX.Element} - The Uploader Fund Code input field.
     */
    const UploaderFundCode = ({ row }) => {
        return (
            <FormInput
                type="text"
                label="Code"
                className="mb-0"
                maxLength="10"
                id={'uploaderFundCode' + row.id}
                defaultValue={row.original.colFundKey}
                onKeyUp={(e) => (row.original.colFundKey = e.target.value)}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };

    /**
     * React component for rendering the Action button.
     *
     * @param {Object} row - The row data for the button.
     * @returns {JSX.Element} - The Action button.
     */
    const Action = ({ row }) => {
        return (
            <div className="d-flex justify-content-center hstack gap-2">
                <ButtonElement
                    icon={<i className="mdi mdi-delete"></i>}
                    tooltip="Delete"
                    className="action-icon hover-danger"
                    onClick={() =>
                        dispatch(
                            fundTypeSoftDelete({
                                orgId: row.original.fkOrgId,
                                fundTypeId: row.original.pkFundTypeId,
                            })
                        )
                    }
                />
                <div className="vr"></div>
                <ButtonElement
                    icon={<i className={'mdi icon-large change-log-icon ' + process.env.REACT_APP_CHANGE_LOG_ICON}></i>}
                    className={
                        process.env.REACT_APP_CHANGE_LOG_HIDE_PLATFORM === true ||
                        process.env.REACT_APP_CHANGE_LOG_HIDE_PLATFORM === 'true'
                            ? 'd-none'
                            : 'action-icon hover-primary custom-svg change-log'
                    }
                    tooltip="Change Log"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) =>
                                    modalDispatch({
                                        type: modalActions.CLOSE_MODAL,
                                    }),
                                modalVariant: 'primary',
                                modalDataKey: ['changeLog', 'changeLogCommonModulePlatform'],
                                data: {
                                    changeLogObj: {
                                        key: `fkFundTypeId`,
                                        value: row?.original?.id,
                                        orgId: row.original.fkOrgId,
                                    },
                                },
                            },
                        })
                    }
                />
            </div>
        );
    };

    /**
     * Updates the selected value.
     *
     * @param {Array} d - The selected value.
     */
    const updateSelectedValue = (d) => {
        setTimeout(() => {
            setSelectedValue(d);
        }, 50);
    };

    /**
     * Handles the select all functionality.
     *
     * @param {boolean} val - The select all value.
     */
    const handleSelectAll = (val) => {
        setIsSelectAll(val);
        if (!val) setData([...data]);
    };

    /**
     * Object containing the table footer action properties.
     */
    const tableFooterAction = {
        title: 'fund type(s) selected',
        selectedValue: selectedValue,
        action: [
            <ButtonElement
                size="sm"
                name="Delete"
                variant="outline-danger"
                onClick={() => {
                    modalDispatch({
                        type: modalActions.OPEN_MODAL,
                        payload: {
                            modalAction: (e) => {
                                let ids = [];
                                let filterCriteria = {};
                                selectedValue?.forEach((id) => {
                                    ids.push(id.orgId);
                                });
                                dispatch(
                                    fundTypeBulkSoftDelete({
                                        fundTypeIdList: isSelectAll ? [] : ids,
                                        fkOrgId: Number(orgFetch?.data?.id),
                                        isSelectAll,
                                        isAnd: true,
                                        filterCriteria,
                                        idsToSkip: uncheckedSelection?.map((m) => m.id),
                                    })
                                );
                            },
                            modalVariant: 'danger',
                            modalDataKey: ['platformAdmin', 'deleteActionsPA'],
                            data: {
                                name: 'bulkHardDeleteBroadcast',
                                title: 'Delete Fund Type(s)',
                                message: 'Are you sure you want to delete this/these fund type(s)?',
                            },
                        },
                    });
                }}
            />,
        ],
    };

    return (
        <>
            {list?.data?.length > 0 ? (
                <p className="text-end custom-font mb-1">Last Edited: {updatedAt}</p>
            ) : (
                <p className="mb-3"></p>
            )}
            <Card>
                <Table
                    columns={TableColumn.editElent.reportData.fundType([FundTypes, UploaderFundCode, Action])}
                    data={data}
                    pageSizes={list?.data?.size}
                    pageNumber={list?.data?.number}
                    totalElements={list?.data?.totalElements}
                    totalPages={list?.data?.totalPages}
                    paginationDispatch={fundTypeList}
                    sizePerPageList={TableColumn.sizePerPageList(data)}
                    isSortable={true}
                    pagination={true}
                    isSearchable={false}
                    isFilter={false}
                    isSelectable={true}
                    loading={loading}
                    theadClass={TableColumn.table_light}
                    tableParentClass="height-fixed"
                    customPaginationProps={{
                        orgId: orgFetch?.data?.id,
                        sort: ['colFundTypeName'],
                    }}
                    tableFooterActionProps={tableFooterAction}
                    updateSelectedValue={updateSelectedValue}
                    handleSelectAll={handleSelectAll}
                    isSelectAll={isSelectAll}
                    resetSelection={resetSelection}
                    setResetSelection={setResetSelection}
                    uncheckedSelection={uncheckedSelection}
                    setUncheckedSelection={setUncheckedSelection}
                    tableClass="fund-type-modal"
                    searchKey={'searchText'}
                />
                <Card.Body className="pt-0">
                    <div className="button-list d-flex justify-content-center">
                        <ButtonElement
                            name="Add New"
                            variant="outline-primary"
                            onClick={() =>
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalAction: (e) =>
                                            modalDispatch({
                                                type: modalActions.CLOSE_MODAL,
                                            }),
                                        modalVariant: 'primary',
                                        modalDataKey: ['platformAdmin', 'newFundType'],
                                        data: {
                                            title: orgFetch?.data?.name + ' > Report Data > Fund Type > Add New',
                                            orgId: orgFetch?.data?.id,
                                        },
                                    },
                                })
                            }
                        />
                        <ButtonElement
                            name="Update"
                            variant="outline-primary"
                            disabled={data?.length <= 0 ? true : false}
                            onClick={() => {
                                let payload = [];
                                data.forEach((item) => {
                                    payload.push({
                                        colFundKey: item.colFundKey,
                                        colFundTypeName: item.colFundTypeName,
                                        pkFundTypeId: item.pkFundTypeId,
                                        orgId: item.fkOrgId,
                                    });
                                });
                                dispatch(fundTypeUpdate(payload));
                            }}
                        />
                        <ButtonElement name="Cancel" variant="light" type="button" onClick={() => props?.hideModal()} />
                    </div>
                </Card.Body>
            </Card>
            <form onSubmit={handleSubmit(onUpload)}>
                <Card>
                    <Card.Header>
                        <h4>Fund Type Uploader</h4>
                    </Card.Header>
                    <Card.Body>
                        <p>
                            <b className="text-primary">STEP 1:</b> Download template:
                        </p>
                        <div className="button-list mt-2 mb-2">
                            <ButtonElement
                                name="Download Excel Template"
                                variant="outline-primary"
                                type="button"
                                onClick={() => dispatch(templateFileDownload({ key: 'fund-type' }))}
                            />
                        </div>
                        <p>
                            <b className="text-primary">STEP 2:</b> Populate all required and relevant fields.
                        </p>
                        <p>
                            <b className="text-primary">STEP 3:</b> Locate, select and upload the completed spreadsheet
                            file:
                        </p>
                        <FormInput
                            type="file"
                            id="chooseFile"
                            register={register}
                            required={true}
                            onChange={(e) => setToggle(e.target.value === '' ? false : true)}
                        />
                        <div className="button-list mt-3">
                            <ButtonElement
                                name="Upload"
                                variant="outline-primary"
                                type="submit"
                                disabled={toggle ? false : true}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </form>
        </>
    );
};

export default FundTypeModal;
