import { RedirectTo } from '../../routes/role/path';
import { getCurrentFiscalYear } from '../retainHistory';

const DATA_FILTER_STORE = 'DATA_FILTER_STORE';
export const FISCAL_YEAR_FILTER_FIELD = [
    'yearList',
    'reportYear',
    'colReportYear',
    'colFdReportYear',
    'colImpactReportYear',
];

const initialData = {
    fiscalYear: null,
    filter: {},
};

/**
 * Sets generic filter data.
 * @param {string} id - The ID of the filter.
 * @param {object} data - The filter data.
 */
export const setGenericFilterData = (id, data) => {
    Object?.keys(data)?.forEach((k) => {
        if (FISCAL_YEAR_FILTER_FIELD.includes(k)) {
            setFiscalYearStore(data[k]?.value);
        }
    });
    setFilterStore(id, data);
};

/**
 * Clears the generic filter data.
 * @param {array} data - The filter data.
 * @param {string} id - The ID of the filter.
 * @returns {object} - The cleared filter data.
 */
export const clearGenericFilterData = (data, id) => {
    setFiscalYearStore();
    setFilterStore(id);
    let obj = JSON.parse(localStorage.getItem(DATA_FILTER_STORE)) || initialData;
    obj?.filter &&
        Object?.keys(obj.filter)?.forEach((n) => {
            Object?.keys(obj.filter[n])?.forEach((k) => {
                if (FISCAL_YEAR_FILTER_FIELD.includes(k)) {
                    obj.filter[n][k] = { label: getFiscalYearStore(), value: getFiscalYearStore() };
                }
            });
        });
    localStorage.setItem(DATA_FILTER_STORE, JSON.stringify(obj));
    if (
        window.location.pathname === RedirectTo('/reports') ||
        window.location.pathname === RedirectTo('/engagement-dashboard')
    ) {
        return null;
    } else {
        let obj = {};
        data?.forEach((k) => {
            if (FISCAL_YEAR_FILTER_FIELD.includes(k?.name)) {
                obj[k.name] = { label: getFiscalYearStore(), value: getFiscalYearStore() };
            }
        });
        return obj;
    }
};

/**
 * Updates the year fields on object.
 * @param {object} data - The object data.
 * @returns {object} - The updated object.
 */
export const updateFiscalYearFields = (data) => {
    Object?.keys(data)?.forEach((k) => {
        if (FISCAL_YEAR_FILTER_FIELD.includes(k)) {
            data[k] = getFiscalYearStore();
        }
    });
    return data;
};

/**
 * Resets the fiscal year store.
 * @param {number} year - The year to reset to.
 */
export const setFiscalYearStore = (year) => {
    let obj = JSON.parse(localStorage.getItem(DATA_FILTER_STORE)) || initialData;
    obj.fiscalYear = year || getCurrentFiscalYear();
    if (year) {
        obj.isReportTableFilterCleared = false;
        obj.systemAdminAnalyticsDashboardReportClear = false;
    }
    localStorage.setItem(DATA_FILTER_STORE, JSON.stringify(obj));
};

/**
 * Gets the fiscal year store.
 * @returns {number} - The fiscal year.
 */
export const getFiscalYearStore = () => {
    return JSON.parse(localStorage.getItem(DATA_FILTER_STORE))?.fiscalYear || getCurrentFiscalYear();
};

/**
 * Sets the filter store.
 * @param {string} id - The ID of the filter.
 * @param {object} data - The filter data.
 */
export const setFilterStore = (id, data) => {
    let obj = JSON.parse(localStorage.getItem(DATA_FILTER_STORE)) || initialData;
    if (data) obj.filter[id] = data;
    else delete obj.filter[id];
    localStorage.setItem(DATA_FILTER_STORE, JSON.stringify(obj));
};

/**
 * Gets the filter store.
 * @param {string} id - The ID of the filter.
 * @returns {object} - The filter data.
 */
export const getFilterStore = (id) => {
    return JSON.parse(localStorage.getItem(DATA_FILTER_STORE))?.filter[id] || {};
};

/**
 * Clears the filter store.
 */
export const clearFilterStore = () => {
    localStorage.removeItem(DATA_FILTER_STORE);
};

/**
 * Report table filter cleared
 * @param {boolean} flag.
 */
export const setReportTableFilterClearedStore = (flag, key) => {
    let obj = JSON.parse(localStorage.getItem(DATA_FILTER_STORE)) || initialData;
    if (key) {
        obj[key] = flag;
    } else {
        obj['isReportTableFilterCleared'] = flag;
    }
    localStorage.setItem(DATA_FILTER_STORE, JSON.stringify(obj));
};

/**
 * Gets the report table filter cleared store.
 * @returns {boolean} - The flag.
 */
export const getReportTableFilterClearedStore = (key) => {
    if (key) {
        return JSON.parse(localStorage.getItem(DATA_FILTER_STORE))?.[key] || false;
    } else {
        return JSON.parse(localStorage.getItem(DATA_FILTER_STORE))?.isReportTableFilterCleared || false;
    }
};
