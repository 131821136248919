// @flow
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useEffect } from 'react';

// component
import $ from 'jquery';
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { addSlickSlider, initialSlideCenter } from '../../../../helpers/editor/slick/SlickSlider';
import { rgcpPanelPreviewList, rgcpPerformancePreviewList, rgcpHtmlReset } from '@ovrture/react-redux';
import {
    arrowInitHandler,
    carouselMigration,
    indicatorsSingleInitHandler,
    RdtLargestPanelHeight,
    setDateFormat,
    hideSelectFundLibOnPreview,
    scriptTagHandler,
    clickEventHandler,
    autoPullUpFeature,
    renderSocialIconAsTextOnRdtPanel,
} from '../../../../helpers/editor/migration/TenantMigration';
import { RedirectTo } from '../../../../routes/role/path';
import { fetchBuildFolderTenantName, fetchS3Url } from '../../../../helpers/s3Mapping';
import { RemoveTenantStyleSheet, ScriptHandler, linkTagHandler } from '../../../../helpers/editor/script/ScriptHandler';
import { WistiaEditorIFrame } from '../../../../helpers/wistia/WistiaEditorIFrame';

const FundPreviewModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [previewData, setpreviewData] = useState();
    const [onloadEventListener, setOnloadEventListener] = useState(false);

    const toggle = () => {
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
        dispatch(rgcpHtmlReset());
    };

    const checkIfMajorValueIsPresent = () => {
        let majors = document.querySelectorAll('.MAJOR');

        for (let i = 0; i < majors.length; i++) {
            let ele = majors[i].querySelector('div');
            if (!ele) {
                majors[i].style.display = 'none';
            }
        }
    };

    const { previewList, details, performancePreview } = useSelector((state) => ({
        details: state?.auth?.me,
        previewList: state?.rgcpHtml?.panelPreview,
        performancePreview: state?.rgcpHtml?.performancePreview,
    }));

    useEffect(() => {
        linkTagHandler(details?.organaisationId);
        // $('head').append(`<link rel="stylesheet" type="text/css" href=${RedirectTo(`/ui/migration.css`)}>`);
        // $('head').append(
        //     `<link rel="stylesheet" type="text/css" href=${RedirectTo(`/ui/${details?.organaisationId}/style.css`)}>`
        // );
        scriptTagHandler(details?.organaisationId);

        if (props?.data?.id && props?.data?.fundTypeFYO !== 'FYO')
            dispatch(
                rgcpPanelPreviewList({
                    rdtProcess: 'RdtPanelPreview',
                    fundType: props?.data?.fromPage,
                    reportYear: props?.data?.reportYear,
                    pkFundId: props?.data?.id,
                })
            );
    }, []);

    useEffect(() => {
        if (onloadEventListener) {
            setOnloadEventListener(false);
            setTimeout(() => {
                window?.impactTypePanelDisplay && window.impactTypePanelDisplay();
                window?.editorAndOutputLib && window.editorAndOutputLib();
            }, 1000);
        }
    }, [onloadEventListener]);

    useEffect(() => {
        if (document?.querySelector('.noPreviewAvailable')) {
            document?.querySelector('.noPreviewAvailable')?.closest('section')?.classList.add('noPreviewParent');
        }
    }, [previewList, previewData]);

    const convertImgSrcToIframeVideo = () => WistiaEditorIFrame(null, null, null, null, 'img');

    const replaceNA = () => {
        document.querySelectorAll('div .value').forEach((ele) => {
            if (ele.innerText === '$N/A') {
                ele.innerText = 'N/A';
            }
        });
    };

    useEffect(() => {
        let styleCss = document.head.querySelectorAll('link');
        setTimeout(() => {
            replaceNA();
        }, 250);
        for (let i = 0; i < styleCss.length; i++) {
            if (styleCss[i].href.includes('style')) {
                if (previewList?.code === 200) {
                    setpreviewData(previewList?.data);
                    let doc = new DOMParser().parseFromString(previewList?.data, 'text/html');

                    setTimeout(() => {
                        convertImgSrcToIframeVideo();
                    }, 1000);

                    document.getElementById('previewOnly').innerHTML = doc?.querySelector('.main-body-container')
                        ?.innerHTML
                        ? doc?.querySelector('.main-body-container')?.innerHTML
                        : doc?.querySelector('body')?.innerHTML;
                    //carouselMigration();
                    checkIfMajorValueIsPresent();
                    if (!window.location.href.includes('reportdata-edit-performance')) {
                        if (!doc?.querySelector('.main-body-container').getElementsByClassName('container')) {
                            document.querySelector('.right.carousel-control').style.display = 'none';
                            document.querySelector('.right.carousel-control span').style.display = 'none';
                        }
                    }

                    if (
                        window.location.href.includes('reportdata-edit-performance') ||
                        window.location.href.includes('reportdata-edit-details')
                    ) {
                        if (document?.querySelector('.sideNote'))
                            document.querySelector('.sideNote').style.display = 'none';
                    }

                    const photoGalleryCarousel = doc?.querySelectorAll('#photoGalleryCarousel .frame_adjuster');
                    photoGalleryCarousel.length > 0 &&
                        setTimeout(() => {
                            addSlickSlider(photoGalleryCarousel);
                            initialSlideCenter('#previewOnly');
                        }, 1500);
                }
            }
        }

        if (previewList?.data === '' || performancePreview?.data === '') {
            setpreviewData('no-data');
        }

        setTimeout(() => {
            let previewOnly = document?.querySelector('#previewOnly');
            previewOnly && previewOnly.classList.remove('hide');
            carouselMigration();
            arrowInitHandler();
            indicatorsSingleInitHandler(0);
            renderSocialIconAsTextOnRdtPanel();
            setDateFormat();
            hideSelectFundLibOnPreview();
            autoPullUpFeature(document?.querySelector('.FUND_IMPACT'));
            hightAdjustNeeded() && RdtLargestPanelHeight();
        }, 1000);

        setTimeout(() => {
            setOnloadEventListener(true);
        }, 1000);
    }, [previewList, performancePreview]);

    const hightAdjustNeeded = () => {
        if (window.location.href.includes('/reportdata-edit-details')) return false;
        return true;
    };

    return (
        <>
            <Card
                onClick={(e) => {
                    if (window.location.pathname.includes('/reportdata-edit-impact')) {
                        clickEventHandler(e);
                    }
                }}>
                {
                    <Card.Body className={'cp-preview-container previewOnly hide'} id="previewOnly">
                        {(props?.data?.fundTypeFYO === 'FYO' || (previewList == null && previewData == 'no-data')) && (
                            <div className="text-center no-preview text-muted">
                                <i className="mdi mdi-eye-off "></i>
                                <p className="font-16">Data not available for preview.</p>
                            </div>
                        )}
                    </Card.Body>
                }
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Back"
                    variant="light"
                    type="button"
                    onClick={() => {
                        RemoveTenantStyleSheet();
                        toggle();
                    }}
                />
            </div>
        </>
    );
};

export default FundPreviewModal;
