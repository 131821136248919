import { GenericFilter, DateRangeFilter, YearFilter } from '../components/table/filter';

const table = {
    platFormAdmin: {
        dashbard: (cell) => [
            {
                Header: 'OrgId #',
                accessor: 'id',
                sort: true,
            },
            {
                Header: 'Tenant',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Status',
                accessor: 'isActive',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Change Log',
                accessor: 'changeLog',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Admin Sign In',
                accessor: 'login',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Users',
                accessor: 'userCount',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: cell[2],
                sort: false,
            },
        ],
        GenericFilter: (props) => <GenericFilter {...props} />,
    },
    editElent: {
        core: {
            basicElements: (cell) => [
                {
                    Header: 'Elements Name',
                    accessor: 'elementName',
                    sort: true,
                },
                {
                    Header: 'Last Edited',
                    accessor: 'lastEdited',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sort: false,
                    Cell: cell[0],
                },
            ],
            broadcast: (cell) => [
                {
                    Header: 'Broadcast #',
                    accessor: 'id',
                    sort: false,
                },
                {
                    Header: 'Elements Name',
                    accessor: 'name',
                    sort: true,
                },
                {
                    Header: 'Last Edited',
                    accessor: 'dateModified',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sort: false,
                    Cell: cell[0],
                },
            ],
        },
        palettes: (cell) => [
            {
                Header: 'Palette #',
                accessor: 'pkPaletteId',
                sort: true,
            },
            {
                Header: 'Palette Name',
                accessor: 'colPaletteName',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'colUpdatedAt',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        brands: (cell) => [
            {
                Header: 'Brands #',
                accessor: 'id',
                sort: true,
            },
            {
                Header: 'Brands Name',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'dateModified',
                sort: true,
            },
            {
                Header: 'Site Edited',
                accessor: 'siteEditedDate',
                sort: false,
            },
            {
                Header: 'Report Edited',
                accessor: 'reportEditedDate',
                sort: false,
            },
            {
                Header: 'General',
                accessor: 'general',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Site Elements',
                accessor: 'siteElements',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Report Elements',
                accessor: 'reportElements',
                sort: false,
                Cell: cell[2],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[3],
            },
        ],
        contentPanel: {
            stadardTemplate: (cell) => [
                {
                    Header: 'Elements #',
                    accessor: 'id',
                    sort: true,
                },
                {
                    Header: 'Elements Name',
                    accessor: 'name',
                    sort: true,
                },
                {
                    Header: 'Palette',
                    accessor: 'paletteName',
                    sort: true,
                },
                {
                    Header: 'Panel Type',
                    accessor: 'forSystemName',
                    sort: true,
                    customAccessor: 'isForSystem',
                },
                {
                    Header: 'Last Edited',
                    accessor: 'dateModified',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions1',
                    sort: false,
                    Cell: cell[0],
                },
            ],
            sitePrintBridge: (cell) => [
                {
                    Header: 'Brands #',
                    accessor: 'id',
                    sort: false,
                },
                {
                    Header: 'Brands Name',
                    accessor: 'name',
                    sort: true,
                },
                {
                    Header: 'Last Edited',
                    accessor: 'dateModified',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sort: false,
                    Cell: cell[0],
                },
            ],
        },
        reportData: {
            reportDataType: (cell) => [
                {
                    Header: 'Elements Name',
                    accessor: 'elementName',
                    sort: true,
                },
                {
                    Header: 'Last Edited',
                    accessor: 'lastEdited',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sort: false,
                    Cell: cell[0],
                },
            ],
            reportData: (cell) => [
                {
                    Header: 'Elements Name',
                    accessor: 'elementName',
                    sort: true,
                },
                {
                    Header: 'Last Edited',
                    accessor: 'lastEdited',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sort: false,
                    Cell: cell[0],
                },
            ],
            fundDescription: (cell) => [
                {
                    Header: ' Field Name',
                    accessor: 'fieldName',
                    sort: false,
                    Cell: cell[0],
                },
                {
                    Header: 'Render',
                    accessor: 'render',
                    sort: false,
                    Cell: cell[1],
                },
                {
                    Header: 'Mandatory',
                    accessor: 'mandatory',
                    sort: false,
                    Cell: cell[2],
                },
                {
                    Header: 'Default Value',
                    accessor: 'defaultValue',
                    sort: false,
                    Cell: cell[3],
                },
                {
                    Header: 'Preprocessing Value',
                    accessor: 'preprocessingValue',
                    sort: false,
                    Cell: cell[4],
                },
            ],
            fundDonor: (cell) => [
                {
                    Header: ' Field Name',
                    accessor: 'fieldName',
                    sort: false,
                    Cell: cell[0],
                },
                {
                    Header: 'Render',
                    accessor: 'render',
                    sort: false,
                    Cell: cell[1],
                },
                {
                    Header: 'Mandatory',
                    accessor: 'mandatory',
                    sort: false,
                    Cell: cell[2],
                },
                {
                    Header: 'Default Value',
                    accessor: 'defaultValue',
                    sort: false,
                    Cell: cell[3],
                },
                {
                    Header: 'Preprocessing Value',
                    accessor: 'preprocessingValue',
                    sort: false,
                    Cell: cell[4],
                },
            ],
            fundImpact: (cell) => [
                {
                    Header: ' Field Name',
                    accessor: 'fieldName',
                    sort: false,
                    Cell: cell[0],
                },
                {
                    Header: 'Render',
                    accessor: 'render',
                    sort: false,
                    Cell: cell[1],
                },
                {
                    Header: 'Mandatory',
                    accessor: 'mandatory',
                    sort: false,
                    Cell: cell[2],
                },
                {
                    Header: 'Default Value',
                    accessor: 'defaultValue',
                    sort: false,
                    Cell: cell[3],
                },
                {
                    Header: 'Preprocessing Value',
                    accessor: 'preprocessingValue',
                    sort: false,
                    Cell: cell[4],
                },
            ],
            fundPerformance: (cell) => [
                {
                    Header: ' Field Name',
                    accessor: 'fieldName',
                    sort: false,
                    Cell: cell[0],
                },
                {
                    Header: 'Render',
                    accessor: 'render',
                    sort: false,
                    Cell: cell[1],
                },
                {
                    Header: 'Mandatory',
                    accessor: 'mandatory',
                    sort: false,
                    Cell: cell[2],
                },
                {
                    Header: 'Default Value',
                    accessor: 'defaultValue',
                    sort: false,
                    Cell: cell[3],
                },
                {
                    Header: 'Preprocessing Value',
                    accessor: 'preprocessingValue',
                    sort: false,
                    Cell: cell[4],
                },
            ],
            fundType: (cell) => [
                {
                    Header: 'Type #',
                    accessor: 'id',
                    sort: false,
                },
                {
                    Header: 'Fund Type',
                    accessor: 'colFundTypeName',
                    sort: true,
                    Cell: cell[0],
                },
                {
                    Header: 'Fund Code',
                    accessor: 'colFundKey',
                    sort: true,
                    Cell: cell[1],
                },
                {
                    Header: 'Actions',
                    accessor: 'fundTypeActions',
                    sort: false,
                    Cell: cell[2],
                },
            ],
            impactType: (cell) => [
                {
                    Header: 'Type #',
                    accessor: 'id',
                    sort: false,
                },
                {
                    Header: 'Impact Type',
                    accessor: 'impactType',
                    sort: true,
                    Cell: cell[0],
                },
                {
                    Header: 'Impact Code',
                    accessor: 'uploaderFundCode',
                    sort: true,
                    Cell: cell[1],
                },
                {
                    Header: 'Action',
                    accessor: 'action',
                    sort: false,
                    Cell: cell[2],
                },
            ],
        },

        reportPanel: {
            reportPanelMap: (cell) => [
                {
                    Header: 'RDT Map #',
                    accessor: 'id',
                    sort: false,
                },
                {
                    Header: 'Template Name',
                    accessor: 'templateName',
                    sort: true,
                },
                {
                    Header: 'RDT Panel Type',
                    accessor: 'colRgcpTypeName',
                    sort: true,
                    customAccessor: 'tblRgcpType.colRgcpTypeName',
                },
                {
                    Header: 'Palette',
                    accessor: 'palette',
                    sort: true,
                    customAccessor: 'tblPalette.colPaletteName',
                },
                {
                    Header: 'Default',
                    accessor: 'colDefault',
                    sort: true,
                },
                {
                    Header: 'Html Type Name',
                    accessor: 'colHtmlTypeName',
                    sort: true,
                },
                {
                    Header: 'Created At',
                    accessor: 'colCreatedAt',
                    sort: true,
                },
                {
                    Header: 'Updated At',
                    accessor: 'colUpdatedAt',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'edit',
                    sort: false,
                    Cell: cell[0],
                },
            ],
            reportPanelHtml: (cell) => [
                {
                    Header: 'RDT Html #',
                    accessor: 'id',
                    sort: false,
                },
                {
                    Header: 'Template Name',
                    accessor: 'templateName',
                    sort: true,
                },
                {
                    Header: 'RDT Panel Type',
                    accessor: 'colRgcpTypeName',
                    sort: true,
                    customAccessor: 'tblRgcpType.colRgcpTypeName',
                },
                {
                    Header: 'Palette',
                    accessor: 'colPaletteName',
                    sort: true,
                    customAccessor: 'tblPalette.colPaletteName',
                },
                {
                    Header: 'Default',
                    accessor: 'colDefault',
                    sort: true,
                },
                {
                    Header: 'Html Type Name',
                    accessor: 'colHtmlTypeName',
                    sort: true,
                },
                {
                    Header: 'Created At',
                    accessor: 'colCreatedAt',
                    sort: true,
                },
                {
                    Header: 'Updated At',
                    accessor: 'colUpdatedAt',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'edit',
                    sort: false,
                    Cell: cell[0],
                },
            ],
        },
        reportPrintBridge: (cell) => [
            {
                Header: 'Brands #',
                accessor: 'id',
                sort: false,
            },
            {
                Header: 'Brands Name',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'dateModified',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    restoresystem: {
        restoresystem: (cell) => [
            {
                Header: 'Tenant',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Restore',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    emailTemplate: {
        emailTemplate: (cell) => [
            {
                Header: 'Email Name',
                accessor: 'colEmailTemplateName',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'colUpdatedAt',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    activeLog: {
        activeLog: (cell) => [
            {
                Header: 'Session ID',
                accessor: 'colSessionId',
                sort: true,
            },
            {
                Header: 'Activity',
                accessor: 'activity',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'App Server',
                accessor: 'colAppServerName',
                sort: true,
            },
            {
                Header: 'Username/Email',
                accessor: 'user.email',
                sort: true,
            },
            {
                Header: 'SSO',
                accessor: 'colLoginWithSso',
                sort: false,
            },
            {
                Header: 'Domain',
                accessor: 'colDomainName',
                sort: true,
            },
            {
                Header: 'Created',
                accessor: 'colCreatedAt',
                sort: true,
            },
            {
                Header: 'Last Active',
                accessor: 'colLastActivityDateTime',
                sort: true,
            },
            {
                Header: 'Destroyed',
                accessor: 'colSessionDestroyedDateTime',
                sort: true,
            },
            {
                Header: 'Time Zone',
                accessor: 'colUserTimeZone',
                sort: true,
            },
            {
                Header: 'Session Link',
                accessor: 'colMouseFlowSessionLink',
                sort: true,
            },
            {
                Header: 'Remote IP',
                accessor: 'colUserRemoteIpAddress',
                sort: true,
            },
            {
                Header: 'User Agent',
                accessor: 'colHttpBrowserInfo',
                sort: true,
            },
            {
                Header: 'As User',
                accessor: 'loginAsUserEmail',
                sort: true,
            },
            {
                Header: 'Session Ended By',
                accessor: 'colLogoutType',
                sort: true,
            },
        ],
        GenericFilter: (props) => <GenericFilter {...props} />,
    },
    activitylogdetail: {
        activitylogdetail: () => [
            {
                Header: 'Session ID',
                accessor: 'colSessionId',
                sort: true,
            },
            {
                Header: 'Remote IP',
                accessor: 'colUserRemoteIpAddress',
                sort: true,
            },
            {
                Header: 'URL',
                accessor: 'colRequestCompleteUrl',
                sort: true,
            },
            {
                Header: 'HTTP Method',
                accessor: 'colHttpMethodType',
                sort: true,
            },
            {
                Header: 'HTTP Status Code',
                accessor: 'colStatusCode',
                sort: true,
            },
            {
                Header: 'HTTP Variables',
                accessor: 'colHttpVariables',
                sort: false,
            },
            {
                Header: 'Started',
                accessor: 'colProcessingStartDateTime',
                sort: true,
            },
            {
                Header: 'Finished',
                accessor: 'colRequestFinishedDateTime',
                sort: true,
            },
            {
                Header: 'Time (ms)',
                accessor: 'colCalculatedTimeSpentInMilisecond',
                sort: true,
            },
            {
                Header: 'User Agent',
                accessor: 'colHttpBrowserInfo',
                sort: true,
            },
            {
                Header: 'As User',
                accessor: 'colLogoutType',
                sort: true,
            },
        ],
    },
    systemAnalytics: {
        systemAnalytics: () => [
            {
                Header: 'Tenant',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Active Users',
                accessor: 'activeUserCount',
                sort: true,
            },
            {
                Header: 'Sites',
                accessor: 'siteCount',
                sort: true,
            },
            {
                Header: 'Reports',
                accessor: 'reportCount',
                sort: true,
            },
            {
                Header: 'Sessions',
                accessor: 'sessionCount',
                sort: true,
            },
            {
                Header: 'Image Uploads',
                accessor: 'imageUploadCount',
                sort: true,
            },
            {
                Header: 'PDF Builds',
                accessor: 'pdfBuildCount',
                sort: true,
            },
        ],
        GenericFilter: (props) => <GenericFilter {...props} />,
    },

    systemAdmin: {
        reviewAndApprovalSites: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'colProspectOwner',
                sort: true,
                Cell: cell[3],
            },
            {
                Header: 'Submitted',
                accessor: 'dateSubmited',
                sort: true,
            },
            {
                Header: 'Review',
                accessor: 'review',
                sort: false,
                Cell: cell[0],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you have the authority to access and review the sites listed below.',
            },
            {
                Header: 'Edit',
                accessor: 'editAction',
                customAccessor: 'colIsEdited',
                sort: true,
                Cell: cell[2],
                descriptor: true,
                descriptorMsg: 'As an administrator, you have the authority to edit the sites listed below.',
            },
            {
                Header: 'Approve',
                accessor: 'approve',
                sort: false,
                Cell: cell[1],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you are responsible for approving the first iteration of the sites listed below. Once approved, the site URL will be activated for sharing. NOTE: Subsequent iterations of each site will not require approval.',
            },
        ],
        GenericFilter: (props) => <GenericFilter {...props} />,
        reviewAndApprovalReports: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'user.colFullName',
                sort: true,
                Cell: cell[3],
            },
            {
                Header: 'Fiscal Year',
                accessor: 'colFiscalYear',
                sort: true,
            },
            {
                Header: 'Submitted',
                accessor: 'colSubmitedDate',
                sort: true,
            },
            {
                Header: 'Review',
                accessor: 'review',
                sort: false,
                Cell: cell[0],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you have the authority to access and review the reports listed below.',
            },
            {
                Header: 'Edit',
                accessor: 'editAction',
                customAccessor: 'colIsEdited',
                sort: true,
                Cell: cell[2],
                descriptor: true,
                descriptorMsg: 'As an administrator, you have the authority to edit the reports listed below.',
            },
            {
                Header: 'Approve',
                accessor: 'approve',
                sort: false,
                Cell: cell[1],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you are responsible for approving the first iteration of the report listed below. Once approved, the report URL will be activated for sharing. NOTE: Subsequent iterations of each report will not require approval.',
            },
        ],
        reviewAndApprovalSurveys: (cell) => [
            {
                Header: 'Last/Corp. Name',
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Survey Name/Cohort',
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Recipient Reference',
                accessor: 'user.colFirstName',
                sort: true,
            },
            {
                Header: 'Email Address',
                accessor: 'user.colRecordOwnerEmailId',
                sort: true,
                Cell: cell[3],
            },
            {
                Header: 'Cell Number',
                accessor: 'colFiscalYear',
                sort: true,
            },
            {
                Header: 'Fund Connect',
                accessor: 'user.colLastName',
                sort: false,
                Cell: cell[4],
            },
            {
                Header: 'Submitted',
                accessor: 'colSubmitedDate',
                sort: true,
            },
            {
                Header: 'Review',
                accessor: 'review',
                sort: false,
                Cell: cell[0],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you have the authority to access and review the reports listed below.',
            },
            {
                Header: 'View/Edit',
                accessor: 'editAction',
                customAccessor: 'colIsEdited',
                sort: false,
                Cell: cell[2],
                descriptor: true,
                descriptorMsg: 'As an administrator, you have the authority to edit the reports listed below.',
            },
            {
                Header: 'Approve',
                accessor: 'approve',
                sort: false,
                Cell: cell[1],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you are responsible for approving the first iteration of the report listed below. Once approved, the report URL will be activated for sharing. NOTE: Subsequent iterations of each report will not require approval.',
            },
        ],
        users: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel('Last Name', localization?.SCOPE_IDENTIFIRE.USERS.DATASCOPEID),
                accessor: 'colLastName',
                sort: true,
                Cell: cell[1],
            },
            {
                Header: localization?.renderCustomLabel('First Name', localization?.SCOPE_IDENTIFIRE.USERS.DATASCOPEID),
                accessor: 'colFirstName',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: 'Role',
                accessor: 'roleName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        viewPortfolio: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'colRecordLastName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'colRecordFirstName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'user.colFullName',
                sort: true,
                cell: cell[3],
            },
            {
                Header: 'Site',
                accessor: 'site',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Latest FY Report',
                accessor: 'latestFyReport',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[2],
            },
        ],
        restoreRecords: (cell) => [
            {
                Header: 'Last/Corp. Name',
                accessor: 'colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Reference Number',
                accessor: 'colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'colRecordOwnerEmailId',
                sort: true,
            },

            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],

        viewSites: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'tblRecords.user.colFullName',
                sort: true,
            },
            {
                Header: 'Analytics & Alerts',
                accessor: 'analytics',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Status',
                accessor: 'siteStatus',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Last Modified',
                accessor: 'colLastEditedAt',
                sort: true,
            },
            {
                Header: 'Approval',
                accessor: 'colApprovedByAdmin',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: 'Edit',
                accessor: 'editAction',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[4],
            },
        ],

        viewReports: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'tblRecords.user.colFullName',
                sort: true,
            },
            {
                Header: 'Fiscal Year',
                accessor: 'colFiscalYear',
                sort: true,
            },
            {
                Header: 'Analytics & Alerts',
                accessor: 'analytics',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Status',
                accessor: 'siteStatus',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Last Modified',
                accessor: 'colLastEditedAt',
                sort: true,
            },
            {
                Header: 'Approval',
                accessor: 'colIsApproved',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: 'Edit',
                accessor: 'editAction',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[4],
            },
        ],
        snapshots: (cell) => [
            {
                Header: 'Date & Time',
                accessor: 'dateTime',
                sort: true,
            },
            {
                Header: 'Activity',
                accessor: 'activity',
                sort: true,
            },
            {
                Header: 'Duration',
                accessor: 'duration',
                sort: true,
            },
            {
                Header: 'Location',
                accessor: 'location',
                sort: true,
            },
            {
                Header: 'Details',
                accessor: 'details',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Snapshot',
                accessor: 'snapshot',
                sort: false,
            },
            {
                Header: 'Actions',
                accessor: 'delete',
                sort: false,
                Cell: cell[1],
            },
        ],
        selectFundWithReportUpdate: (cell) => [
            {
                Header: 'Record',
                accessor: 'colRecordReferenceNo',
                sort: true,
                Cell: cell[3],
                columnFreeze: {
                    freezed: true,
                    left: '0',
                    hbg: '#eff4f5',
                },
            },
            {
                Header: 'Record Name',
                accessor: 'colRecordFullName',
                sort: true,
                Cell: cell[4],
                columnFreeze: {
                    freezed: true,
                    left: '140',
                    hbg: '#eff4f5',
                },
            },
            {
                Header: 'All?',
                accessor: 'All?',
                Cell: cell[0],
                isHaveCheckbox: {
                    checkbox: true,
                    eventCheckbox: cell[5],
                },
                columnFreeze: {
                    freezed: true,
                    left: '310',
                    hbg: '#eff4f5',
                    freezedBorder: true,
                },
            },
            ...Array.from({ length: cell[2] }, (_, index) => ({
                Header: `Fund ${index + 1}`,
                accessor: `Fund ${index + 1}`,
                Cell: ({ row }) => cell[1](row, index),
            })),
        ],

        editorsList: (cell) => [
            {
                Header: 'Username',
                accessor: 'userName',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'lastEdited',
                sort: true,
            },
            {
                Header: 'Action',
                accessor: 'action',
                sort: true,
            },
        ],
        editMembership: (cell) => [
            {
                Header: 'User Name',
                accessor: 'lastName',
                sort: false,
            },
        ],
        analytics: (cell, localization) => [
            {
                Header: 'Date & Time',
                accessor: 'colCreatedAt',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
                Cell: cell[4],
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
                Cell: cell[5],
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
                Cell: cell[6],
            },
            {
                Header: 'Owner',
                accessor: 'user.colFullName',
                sort: true,
                Cell: cell[3],
            },
            {
                Header: 'Score',
                accessor: 'score',
                sort: true,
                Cell: cell[0],
                customAccessor: 'colScore',
            },
            {
                Header: 'Activity',
                accessor: 'activity',
                sort: true,
                customAccessor: 'tblActivity.colActivityName',
            },
            {
                Header: 'Type',
                accessor: 'type',
                sort: true,
                customAccessor: 'tblType.colTypeName',
            },
            {
                Header: 'Duration',
                accessor: 'duration',
                sort: true,
                customAccessor: 'tblRecording.colRecordingDuration',
            },
            {
                Header: 'Recording',
                accessor: 'details',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[2],
            },
        ],
        DateRangeFilter: (...props) => <DateRangeFilter {...props} />,
        analyticsDashboardSiteData: (cell) => [
            {
                Header: 'SYSTEM TOTALS (ALL TIME)',
                accessor: 'systemTotal',
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotal',
            },
        ],
        analyticsDashboardReportData: (cell) => [
            {
                Header: 'SYSTEM TOTALS (ALL TIME)',
                accessor: 'systemTotal',
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotal',
            },
        ],
        analyticsDashboardSiteFilterData: (cell) => [
            {
                Header: 'FILTER RESULTS',
                accessor: 'filterResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on the filters applied.',
            },
        ],
        analyticsDashboardReportFilterData: (cell) => [
            {
                Header: 'FILTER RESULTS',
                accessor: 'filterResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on the filters applied.',
            },
        ],
        analyticsDashboardSiteOpensData: (cell) => [
            {
                Header: 'OPENS',
                accessor: 'opensResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on the filters applied.',
            },
        ],
        analyticsDashboardReportOpensData: (cell) => [
            {
                Header: 'OPENS',
                accessor: 'opensResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on the filters applied.',
            },
        ],
        analyticsDashboardSiteEngagementData: (cell) => [
            {
                Header: 'ENGAGEMENT',
                accessor: 'engagementResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on the filters applied.',
            },
        ],
        analyticsDashboardReportEngagementData: (cell) => [
            {
                Header: 'ENGAGEMENT',
                accessor: 'engagementResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on the filters applied.',
            },
        ],
        exportArchiveData: (cell) => [
            {
                Header: 'Export',
                accessor: 'export',
                sort: true,
            },
            {
                Header: 'User',
                accessor: 'user.colFullName',
                sort: true,
            },
            {
                Header: 'Date',
                accessor: 'date',
                sort: true,
            },
            {
                Header: 'File Size',
                accessor: 'fileSize',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        manageDownloads: (cell) => [
            {
                Header: 'Fiscal Year',
                accessor: 'year',
                sort: true,
            },
            {
                Header: 'File Name',
                accessor: 'fileName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: cell[0],
            },
        ],
        currentEditors: (cell) => [
            {
                Header: 'Username',
                accessor: 'userName',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'lastEdited',
                sort: true,
            },
            {
                Header: 'Action',
                accessor: 'action',
                sort: true,
            },
        ],
        manageApiKey: (cell) => [
            {
                Header: 'Access Key ID',
                accessor: 'accessKeyId',
                sort: false,
            },
            {
                Header: 'Created',
                accessor: 'created',
                sort: false,
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: cell[0],
            },
        ],
        createdAccessKey: (cell) => [
            {
                Header: 'Access Key ID',
                accessor: 'accessKeyId',
                sort: false,
            },
            {
                Header: 'Secret Key',
                accessor: 'secretKey',
                Cell: cell[0],
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: cell[1],
            },
        ],
    },
    Common: {
        LinkSurveyField: (cell) => [
            {
                Header: 'Survey Field Name',
                accessor: 'colFieldName',
                sort: true,
            },
        ],
        LinkFunds: (cell, localization) => [
            {
                Header: localization.renderCustomLabel(
                    'Fund Name',
                    localization.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'fundName',
                sort: true,
            },
            {
                Header: '',
                accessor: 'status',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: localization.renderCustomLabel(
                    'Fund Reference #',
                    localization.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'referenceNumber',
                sort: true,
            },
        ],
        multipleSitesLeft: (cell) => [
            {
                Header: 'Name',
                accessor: 'lastName',
                sort: true,
            },
        ],
        multipleSitesRight: (cell, localization) => [
            {
                Header: 'Name',
                accessor: 'lastName',
                sort: true,
            },
            {
                Header: 'Brand',
                accessor: 'brand',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Slot 1',
                accessor: 'slot1',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Slot 2',
                accessor: 'slot2',
                sort: false,
                Cell: cell[2],
            },
            {
                Header: 'Slot 3',
                accessor: 'slot3',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Slot 4',
                accessor: 'slot4',
                sort: false,
                Cell: cell[4],
            },
            {
                Header: 'Slot 5',
                accessor: 'slot5',
                sort: false,
                Cell: cell[5],
            },
            {
                Header: 'Slot 6',
                accessor: 'slot6',
                sort: false,
                Cell: cell[6],
            },
            {
                Header: 'Slot 7',
                accessor: 'slot7',
                sort: false,
                Cell: cell[7],
            },
            {
                Header: 'Slot 8',
                accessor: 'slot8',
                sort: false,
                Cell: cell[8],
            },
            {
                Header: 'Slot 9',
                accessor: 'slot9',
                sort: false,
                Cell: cell[9],
            },
            {
                Header: 'Slot 10',
                accessor: 'slot10',
                sort: false,
                Cell: cell[10],
            },
            {
                Header: 'Slot 11',
                accessor: 'slot11',
                sort: false,
                Cell: cell[11],
            },
            {
                Header: 'Slot 12',
                accessor: 'slot12',
                sort: false,
                Cell: cell[12],
            },
        ],
        multipleReportsLeft: (cell) => [
            {
                Header: 'Name',
                accessor: 'lastName',
                sort: true,
            },
        ],
        multipleReportsRight: (cell, localization) => [
            {
                Header: 'Name',
                accessor: 'lastName',
                sort: true,
            },
            {
                Header: 'Brand',
                accessor: 'brand',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Slot 1',
                accessor: 'slot1',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Slot 2',
                accessor: 'slot2',
                sort: false,
                Cell: cell[2],
            },
            {
                Header: 'Slot 3',
                accessor: 'slot3',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Slot 4',
                accessor: 'slot4',
                sort: false,
                Cell: cell[4],
            },
            {
                Header: 'Slot 5',
                accessor: 'slot5',
                sort: false,
                Cell: cell[5],
            },
            {
                Header: 'Slot 6',
                accessor: 'slot6',
                sort: false,
                Cell: cell[6],
            },
            {
                Header: 'Slot 7',
                accessor: 'slot7',
                sort: false,
                Cell: cell[7],
            },
            {
                Header: 'Slot 8',
                accessor: 'slot8',
                sort: false,
                Cell: cell[8],
            },
            {
                Header: 'Slot 9',
                accessor: 'slot9',
                sort: false,
                Cell: cell[9],
            },
            {
                Header: 'Slot 10',
                accessor: 'slot10',
                sort: false,
                Cell: cell[10],
            },
            {
                Header: 'Slot 11',
                accessor: 'slot11',
                sort: false,
                Cell: cell[11],
            },
            {
                Header: 'Slot 12',
                accessor: 'slot12',
                sort: false,
                Cell: cell[12],
            },
            {
                Header: 'Slot 13',
                accessor: 'slot13',
                sort: false,
                Cell: cell[13],
            },
            {
                Header: 'Slot 14',
                accessor: 'slot14',
                sort: false,
                Cell: cell[14],
            },
            {
                Header: 'Slot 15',
                accessor: 'slot15',
                sort: false,
                Cell: cell[15],
            },
            {
                Header: 'Slot 16',
                accessor: 'slot16',
                sort: false,
                Cell: cell[16],
            },
            {
                Header: 'Slot 17',
                accessor: 'slot17',
                sort: false,
                Cell: cell[17],
            },
            {
                Header: 'Slot 18',
                accessor: 'slot18',
                sort: false,
                Cell: cell[18],
            },
        ],
    },
    users: {
        users: (cell) => [
            {
                Header: 'Users',
                accessor: 'colFullName',
                sort: true,
            },
            {
                Header: 'Username/Email',
                accessor: 'email',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    accounts: {
        accounts: (cell) => [
            {
                Header: 'Browser Detail',
                accessor: 'colDeviceName',
                sort: true,
            },
            {
                Header: 'Last Sign In',
                accessor: 'colDeviceSuccessLoginAt',
                sort: true,
            },
            {
                Header: 'Sign In Type',
                accessor: 'colLoginType',
                sort: true,
            },
        ],
    },
    efsFileChecker: {
        efsFileChecker: (cell) => [
            {
                Header: 'File Name',
                accessor: 'colFilesName',
                sort: true,
            },
            {
                Header: 'Created Date',
                accessor: 'newCreatedDate',
                sort: false,
            },
            {
                Header: 'Download URL',
                accessor: 'colDownloadUrl',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    deletedData: {
        restoreUsers: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel('Last Name', localization?.SCOPE_IDENTIFIRE.USERS.DATASCOPEID),
                accessor: 'colLastName',
                sort: true,
                Cell: cell[1],
            },
            {
                Header: localization?.renderCustomLabel('First Name', localization?.SCOPE_IDENTIFIRE.USERS.DATASCOPEID),
                accessor: 'colFirstName',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: 'Role',
                accessor: 'roleName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreRecords: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'colRecordLastName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'colRecordFirstName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'colRecordOwnerEmailId',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'colUpdatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreSites: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'tblRecords.user.colFullName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreReports: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'tblRecords.user.colFullName',
                sort: true,
            },
            {
                Header: 'Fiscal Year',
                accessor: 'colFiscalYear',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreContentPanels: (cell) => [
            {
                Header: 'Panel Name',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'dateModified',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'dateCreated',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreAssets: (cell) => [
            {
                Header: 'Asset Name',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'dateModified',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreContentPanels: (cell) => [
            {
                Header: 'Content Panel Name',
                accessor: 'name',
                sort: true,
                Cell: cell[0],
            },
            {
                Header: 'Time',
                accessor: 'dateModified',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[1],
            },
        ],
        reportDataFundDetailsRestore: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Fund Name',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'colFundName',
                sort: true,
                Cell: cell[1],
            },
            {
                Header: localization?.renderCustomLabel(
                    'Fund Reference #',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'colFundReferenceNo',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: localization?.renderCustomLabel(
                    'Fund Type',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'colFundTypeName',
                customAccessor: 'tblFundType.colFundTypeName',
                sort: true,
                Cell: cell[3],
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        reportDataFYPerformanceRestore: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Fund Name',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'tblFund.colFundName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Fund Reference #',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'tblFund.colFundReferenceNo',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Fund Type',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'tblFund.colFundTypeName',
                sort: true,
            },
            {
                Header: 'Fiscal Year',
                accessor: 'colFpReportYear',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        reportDataFYDonorsRestore: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Fund Name',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'tblFund.colFundName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Fund Reference #',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'tblFund.colFundReferenceNo',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Fund Type',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'tblFund.colFundTypeName',
                sort: true,
            },
            {
                Header: 'Fiscal Year',
                accessor: 'colFdReportYear',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Last/Corporation Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'First Name',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        reportDataFYImpactRestore: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Fund Name',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'tblFund.colFundName',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Fund Reference #',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'colFundReferenceNo',
                sort: true,
            },
            {
                Header: localization?.renderCustomLabel(
                    'Impact Type',
                    localization?.SCOPE_IDENTIFIRE.FY_IMPACT.DATASCOPEID
                ),
                accessor: 'tblImpactType.colImpactName',
                sort: true,
            },
            {
                Header: 'Fiscal Year',
                accessor: 'colImpactReportYear',
                sort: true,
            },
            {
                Header: 'Impact Name',
                accessor: 'colImpactFullName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    deletedDataPA: {
        restoreUsers: (cell) => [
            {
                Header: 'Last Name',
                accessor: 'colLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'colFirstName',
                sort: true,
            },
            {
                Header: 'Role',
                accessor: 'role.name',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreTenants: (cell) => [
            {
                Header: 'Org Id',
                accessor: 'colRecordLastName',
                sort: true,
            },
            {
                Header: 'Name',
                accessor: 'colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreBroadcast: (cell) => [
            {
                Header: 'Broadcast Name',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restorePalette: (cell) => [
            {
                Header: 'Palette #',
                accessor: 'pkPaletteId',
                sort: true,
            },
            {
                Header: 'Palette Name',
                accessor: 'colPaletteName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreBrands: (cell) => [
            {
                Header: 'Brand #',
                accessor: 'id',
                sort: true,
            },
            {
                Header: 'Brand Name',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreSiteElement: (cell) => [
            {
                Header: 'Brand Name',
                accessor: 'templateName',
                sort: true,
            },
            {
                Header: 'Palette Name',
                accessor: 'paletteName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreReportElement: (cell) => [
            {
                Header: 'Brand Name',
                accessor: 'templateName',
                sort: true,
            },
            {
                Header: 'Palette Name',
                accessor: 'paletteName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreContentPanels: (cell) => [
            {
                Header: 'Panel #',
                accessor: 'id',
                sort: true,
            },
            {
                Header: 'Panel Name',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Palette Name',
                accessor: 'paletteName',
                sort: true,
            },
            {
                Header: 'Panel Type',
                accessor: 'isForSystem',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreFundType: (cell) => [
            {
                Header: 'Type Name',
                accessor: 'colFundTypeName',
                sort: true,
            },
            {
                Header: 'Type Key',
                accessor: 'colFundKey',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreImpactType: (cell) => [
            {
                Header: 'Type Name',
                accessor: 'colImpactName',
                sort: true,
            },
            {
                Header: 'Type Key',
                accessor: 'colImpactKey',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreAccessCriteria: (cell) => [
            {
                Header: 'Palette',
                accessor: 'paletteName',
                customAccessor: 'tblPalette.colPaletteName',
                sort: true,
            },
            {
                Header: 'Fund Type',
                accessor: 'fundTypeName',
                customAccessor: 'tblFundType.colFundTypeName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreAccessCriteriaImpact: (cell) => [
            {
                Header: 'Palette',
                accessor: 'paletteName',
                customAccessor: 'tblPalette.colPaletteName',
                sort: true,
            },
            {
                Header: 'Impact Type',
                accessor: 'impactTypeName',
                customAccessor: 'tblImpactType.colImpactName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreRgcpHtml: (cell) => [
            {
                Header: 'Template Name',
                accessor: 'templateName',
                sort: true,
            },
            {
                Header: 'Type',
                accessor: 'colRgcpTypeName',
                sort: true,
            },
            {
                Header: 'Palette',
                accessor: 'colPaletteName',
                sort: true,
            },
            {
                Header: 'Default',
                accessor: 'colDefault',
                sort: true,
            },
            {
                Header: 'Html Type Name',
                accessor: 'colHtmlTypeName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreRgcpHtmlMap: (cell) => [
            {
                Header: 'Template Name',
                accessor: 'templateName',
                sort: true,
            },
            {
                Header: 'Type',
                accessor: 'colRgcpTypeName',
                sort: true,
            },
            {
                Header: 'Palette',
                accessor: 'palette',
                sort: true,
            },
            {
                Header: 'Default',
                accessor: 'colDefault',
                sort: true,
            },
            {
                Header: 'Html Type Name',
                accessor: 'colHtmlTypeName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreEmailTemplates: (cell) => [
            {
                Header: 'Template #',
                accessor: 'pkEmailTemplateId',
                sort: true,
            },
            {
                Header: 'Template Name',
                accessor: 'colEmailTemplateName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreSitePrintBridge: (cell) => [
            {
                Header: 'Brand Name',
                accessor: 'templateName',
                sort: true,
            },
            {
                Header: 'Palette Name',
                accessor: 'tblPalette.colPaletteName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        restoreReportPrintBridge: (cell) => [
            {
                Header: 'Brand Name',
                accessor: 'templateName',
                sort: true,
            },
            {
                Header: 'Palette Name',
                accessor: 'tblPalette.colPaletteName',
                sort: true,
            },
            {
                Header: 'Time',
                accessor: 'actionCreatedAt',
                sort: true,
            },
            {
                Header: 'Deleted By',
                accessor: 'loggedInUserFullName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    changeLog: {
        changeLog: (cell) => {
            if (cell[0] === 'change-log') {
                return [
                    {
                        Header: 'Time',
                        accessor: 'colCreatedAt',
                        sort: true,
                    },
                    {
                        Header: 'Actor',
                        accessor: 'loggedInUserFullName',
                        sort: true,
                    },
                    {
                        Header: 'Role',
                        accessor: 'loggedInUserRoleName',
                        sort: false,
                    },
                    {
                        Header: 'Status',
                        accessor: 'colIsActionSucceeded',
                        sort: false,
                        Cell: cell[0],
                    },
                    {
                        Header: 'Medium',
                        accessor: 'colMediumName',
                        customAccessor: 'tblActivityLogMedium.colMediumName',
                        sort: true,
                    },
                    {
                        Header: 'Event',
                        accessor: 'colActionDetails',
                        customAccessor: 'tblActivityLogAction.colActionDetails',
                        sort: true,
                    },
                    {
                        Header: 'Module',
                        accessor: 'colModuleName',
                        customAccessor: 'tblActivityLogModule.colModuleName',
                        sort: true,
                    },
                    {
                        Header: 'Component',
                        accessor: 'colComponentName',
                        customAccessor: 'tblActivityLogComponent.colComponentName',
                        sort: true,
                    },
                    {
                        Header: 'Data Reference #1',
                        accessor: 'colDataReference1',
                        sort: true,
                    },
                    {
                        Header: 'Data Reference #2',
                        accessor: 'colDataReference2',
                        sort: true,
                    },
                    {
                        Header: 'Owner',
                        accessor: 'ownerFullName',
                        sort: true,
                    },
                    {
                        Header: 'Change Detail/Target',
                        accessor: 'colIsChangesPerformedAvailable',
                        sort: false,
                    },
                ];
            } else if (cell[0] === 'user-activity-log') {
                return [
                    {
                        Header: 'Time',
                        accessor: 'colCreatedAt',
                        sort: true,
                    },
                    {
                        Header: 'Actor',
                        accessor: 'loggedInUserFullName',
                        sort: true,
                    },
                    {
                        Header: 'Role',
                        accessor: 'loggedInUserRoleName',
                        sort: false,
                    },
                    {
                        Header: 'Status',
                        accessor: 'colIsActionSucceeded',
                        sort: false,
                        Cell: cell[0],
                    },
                    {
                        Header: 'Medium',
                        accessor: 'colMediumName',
                        customAccessor: 'tblActivityLogMedium.colMediumName',
                        sort: true,
                    },
                    {
                        Header: 'Event',
                        accessor: 'colActionDetails',
                        customAccessor: 'tblActivityLogAction.colActionDetails',
                        sort: true,
                    },
                    {
                        Header: 'Module',
                        accessor: 'colModuleName',
                        customAccessor: 'tblActivityLogModule.colModuleName',
                        sort: true,
                    },
                    {
                        Header: 'Component',
                        accessor: 'colComponentName',
                        customAccessor: 'tblActivityLogComponent.colComponentName',
                        sort: true,
                    },
                    {
                        Header: 'Data Reference #1',
                        accessor: 'colDataReference1',
                        sort: true,
                    },
                    {
                        Header: 'Data Reference #2',
                        accessor: 'colDataReference2',
                        sort: true,
                    },
                    {
                        Header: 'Owner',
                        accessor: 'ownerFullName',
                        sort: true,
                    },
                    {
                        Header: 'Change Detail/Target',
                        accessor: 'colIsChangesPerformedAvailable',
                        sort: false,
                    },
                ];
            } else {
                return [
                    {
                        Header: 'Time',
                        accessor: 'colCreatedAt',
                        sort: true,
                    },
                    {
                        Header: 'Actor',
                        accessor: 'loggedInUserFullName',
                        sort: true,
                    },
                    {
                        Header: 'Role',
                        accessor: 'loggedInUserRoleName',
                        sort: false,
                    },
                    {
                        Header: 'Status',
                        accessor: 'colIsActionSucceeded',
                        sort: false,
                        Cell: cell[0],
                    },
                    {
                        Header: 'Medium',
                        accessor: 'colMediumName',
                        customAccessor: 'tblActivityLogMedium.colMediumName',
                        sort: true,
                    },
                    {
                        Header: 'Event',
                        accessor: 'colActionDetails',
                        customAccessor: 'tblActivityLogAction.colActionDetails',
                        sort: true,
                    },
                    {
                        Header: 'Module',
                        accessor: 'colModuleName',
                        customAccessor: 'tblActivityLogModule.colModuleName',
                        sort: true,
                    },
                    {
                        Header: 'Component',
                        accessor: 'colComponentName',
                        customAccessor: 'tblActivityLogComponent.colComponentName',
                        sort: true,
                    },
                    {
                        Header: 'Data Reference #1',
                        accessor: 'colDataReference1',
                        sort: true,
                    },
                    {
                        Header: 'Data Reference #2',
                        accessor: 'colDataReference2',
                        sort: true,
                    },
                    {
                        Header: 'Owner',
                        accessor: 'ownerFullName',
                        sort: true,
                    },
                    {
                        Header: 'Change Detail/Target',
                        accessor: 'colIsChangesPerformedAvailable',
                        sort: false,
                    },
                ];
            }
        },
        changeLogViewDetails: (cell) => [
            {
                Header: 'Field Name',
                accessor: 'fieldName',
                sort: false,
            },
            {
                Header: 'Updated Value',
                accessor: 'updatedValue',
                sort: false,
            },
        ],
    },
    contentPanel: {
        reportData: (cell, localization) => [
            {
                Header: localization?.renderCustomLabel(
                    'Fund Name',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'colFundName',
                sort: true,
                Cell: cell[4],
            },
            {
                Header: localization?.renderCustomLabel(
                    'Fund Reference #',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'colFundReferenceNo',
                sort: true,
                Cell: cell[5],
            },
            {
                Header: localization?.renderCustomLabel(
                    'Fund Type',
                    localization?.SCOPE_IDENTIFIRE.FUND_DETAILS.DATASCOPEID
                ),
                accessor: 'colFundTypeName',
                customAccessor: 'tblFundType.colFundTypeName',
                sort: true,
                Cell: cell[6],
            },
            {
                Header: 'Fiscal Year',
                accessor: 'reportYear',
                sort: false,
            },
            {
                Header: 'Details',
                accessor: 'details',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'FY Performance',
                accessor: 'fyPerformance',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'FY Donor',
                accessor: 'donor',
                sort: false,
                Cell: cell[2],
            },
            {
                Header: 'FY Impact',
                accessor: 'fyImpact',
                sort: false,
                Cell: cell[3],
            },
        ],

        GenericFilter: (props) => <GenericFilter {...props} />,
        linkedRecords: (cell) => [
            {
                Header: 'Name',
                accessor: 'Name',
            },
            {
                Header: 'Primary Record #',
                accessor: 'recordReference',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        duplicate: (cell, localization) => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: localization?.renderCustomLabel(
                    'Primary Record #',
                    localization?.SCOPE_IDENTIFIRE.RECORDS.DATASCOPEID
                ),
                accessor: 'recordReference',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        impactSort: (cell) => [
            {
                Header: 'Impact Name',
                accessor: 'Name',
            },

            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    apiConnection: {
        crm: (cell) => [
            {
                Header: 'Application',
                accessor: 'colDescription',
                sort: false,
            },
            {
                Header: 'Status',
                accessor: 'status',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[1],
            },
        ],
        crmMapping: (cell) => [
            {
                Header: 'pkMappingId',
                accessor: 'pkMappingId',
                sort: false,
            },
            {
                Header: 'Ovrture Column Name',
                accessor: 'colDisplayName',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Limitations',
                accessor: 'colRemark',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'CRM Column Name',
                accessor: 'colCrmFieldName',
                sort: false,
            },
        ],
    },
    surveys: {
        surveys: (cell) => [
            {
                Header: 'Survey Name/Cohort',
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: 'Status',
                accessor: 'siteStatus',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Fiscal Year',
                accessor: 'colFiscalYear',
                sort: true,
            },
            {
                Header: 'Total Recipients',
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Manage',
                accessor: 'analytics',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Submissions',
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },

            {
                Header: 'Approved',
                accessor: 'tblRecords.user.colFullName',
                sort: true,
            },
            {
                Header: 'Pending',
                accessor: 'tblRecords.pending',
                sort: true,
            },
            {
                Header: 'Non-Submissions',
                accessor: 'tblRecords.non-submissions',
                sort: true,
            },
            {
                Header: 'Created',
                accessor: 'colCreatedAt',
                sort: true,
            },
            {
                Header: 'Last Modified',
                accessor: 'colLastEditedAt',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[2],
            },
        ],
        manageSurveyRecipients: (cell) => [
            {
                Header: 'Last/program Name',
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Recipient reference',
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Status',
                accessor: 'siteStatus',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Analytics/Alerts',
                accessor: 'analytics',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Email Address',
                accessor: 'tblRecords.colRecordOwnerEmailId',
                sort: true,
            },
            {
                Header: 'Cell number',
                accessor: 'tblRecords.colRecordOwnerCellNumber',
                sort: true,
            },
            {
                Header: 'Fund Connection',
                accessor: 'fundConnection',
                sort: false,
                Cell: cell[2],
            },
            {
                Header: 'View/Edit',
                accessor: 'editAction',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[4],
            },
        ],
        surveyRecipients: (cell) => [
            {
                Header: 'Last/program Name',
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Survey Name/Cohort',
                accessor: 'tblRecords.colRecordReferenced',
                sort: true,
            },
            {
                Header: 'Recipient reference',
                accessor: 'tblRecords.colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Survey Status',
                accessor: 'siteStatus',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Email Address',
                accessor: 'tblRecords.colRecordOwnerEmailId',
                sort: true,
            },
            {
                Header: 'Cell number',
                accessor: 'tblRecords.colRecordOwnerCellNumber',
                sort: true,
            },
            {
                Header: 'Fund Connection',
                accessor: 'fundConnection',
                sort: false,
                Cell: cell[2],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[4],
            },
        ],
    },
    sizePerPageList: (data) => [
        {
            text: 'All',
            value: data?.length,
        },
    ],
    table_light: 'table-light',
    pageSize: 50,
};

export { table };
