// @flow
import React, { useEffect, useState } from 'react';
import { Col, Card } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import DatabaseField from './dataSchemaFieldDetails/DatabaseField';
import { useForm } from 'react-hook-form';
import NamingField from './dataSchemaFieldDetails/NamingField';
import ReportRendaringField from './dataSchemaFieldDetails/ReportRendaringField';
import SurveyDefaultField from './dataSchemaFieldDetails/SurveyDefaultField';
import { dataMapInfo, dataMapUpdate, dataMapReset, dataMapUpdateReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import service from '../../../constants/service.constant';
import { COMMON_PATH } from '../../../routes/role/path';
import { useHistory } from 'react-router-dom';
import { useLocalization } from '@ovrture/react-redux/dist/helpers/dataMap/DataMapLocalizationContext';

const DataSchemaFieldDetails = (props) => {
    const { surveyStoreUpdate } = useLocalization();
    const history = useHistory();
    const dispatchAction = useDispatch();
    const [isApplyAndClose, setIsApplyAndClose] = useState(false);
    const [reportRenderingLinkedIdList, setreportRenderingLinkedIdList] = useState([]);
    const {
        control,
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();
    const values = watch();

    const { info, update, list } = useSelector((state) => ({
        info: state?.dataMap?.info,
        update: state?.dataMap?.update,
        list: state?.dataMap?.field?.data,
    }));

    useEffect(() => {
        if (values) {
            const data = values;
            props?.data?.setHaveChange(false);

            // Database Fields
            if (info?.data?.colReadOnlyFields) {
                let colReadOnlyFieldsObj = JSON.parse(info.data.colReadOnlyFields);
                if (colReadOnlyFieldsObj.mandatory.value !== data.mandatory) {
                    props?.data?.setHaveChange(true);
                    return;
                }
            }

            // Naming Fields
            if (info?.data?.colNamingFields) {
                let colNamingFieldsObj = JSON.parse(info.data.colNamingFields);
                if (colNamingFieldsObj.customLabel.value !== (data.customLabel || '')) {
                    props?.data?.setHaveChange(true);
                    return;
                }
                if (colNamingFieldsObj.crmFieldName.value !== (data.crmFieldName || '')) {
                    props?.data?.setHaveChange(true);
                    return;
                }
            }

            // ReportRendering Fields
            if (info?.data?.colReportRenderingFields) {
                let colReportRenderingFieldsObj = JSON.parse(info.data.colReportRenderingFields);
                if (colReportRenderingFieldsObj.mandatoryNullvalueBoolean.value !== data.mandatoryNullvalueBoolean) {
                    props?.data?.setHaveChange(true);
                    return;
                }
                if (colReportRenderingFieldsObj.mandatoryNullvalueText.value !== (data.mandatoryNullvalueText || '')) {
                    props?.data?.setHaveChange(true);
                    return;
                }
                if (
                    !areArraysEqual(
                        info.data.reportRenderingLinkedIdList,
                        reportRenderingLinkedIdList.map((i) => i.id)
                    )
                ) {
                    props?.data?.setHaveChange(true);
                    return;
                }
            }

            // SurveyDefaults Fields
            if (info?.data?.colSurveyDefaultsFields) {
                let colSurveyDefaultsFieldsObj = JSON.parse(info.data.colSurveyDefaultsFields);
                if (colSurveyDefaultsFieldsObj.defaultSurveyQuestion.value !== (data.defaultSurveyQuestion || '')) {
                    props?.data?.setHaveChange(true);
                    return;
                }
                if (colSurveyDefaultsFieldsObj.defaultSurveyQualifier.value !== (data.defaultSurveyQualifier || '')) {
                    props?.data?.setHaveChange(true);
                    return;
                }
            }
        }
    }, [values]);

    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        if (props?.data?.selectedField?.pkDataMapId) {
            dispatchAction(dataMapReset());
            dispatchAction(
                dataMapInfo({
                    pkDataMapId: props?.data?.selectedField?.pkDataMapId,
                })
            );
        }
    }, [props?.data?.selectedField?.pkDataMapId]);

    useEffect(() => {
        if (update?.code === service.accepted) {
            dispatchAction(dataMapUpdateReset());
            if (isApplyAndClose) {
                dispatchAction(dataMapReset());
                props?.data?.setcloseTab(true);
            } else {
                if (props?.data?.selectedField?.pkDataMapId) {
                    dispatchAction(
                        dataMapInfo({
                            pkDataMapId: props?.data?.selectedField?.pkDataMapId,
                        })
                    );
                }
            }
        }
    }, [update]);

    const onSubmit = (data) => {
        // Database Fields
        if (info?.data?.colReadOnlyFields) {
            let colReadOnlyFieldsObj = JSON.parse(info.data.colReadOnlyFields);
            colReadOnlyFieldsObj.mandatory.value = data.mandatory || false;
            info.data.colReadOnlyFields = JSON.stringify(colReadOnlyFieldsObj);
        }

        // Naming Fields
        if (info?.data?.colNamingFields) {
            let colNamingFieldsObj = JSON.parse(info.data.colNamingFields);
            colNamingFieldsObj.customLabel.value = data.customLabel || '';
            colNamingFieldsObj.crmFieldName.value = data.crmFieldName || '';
            info.data.colNamingFields = JSON.stringify(colNamingFieldsObj);
        }

        // ReportRendering Fields
        if (info?.data?.colReportRenderingFields) {
            let colReportRenderingFieldsObj = JSON.parse(info.data.colReportRenderingFields);
            colReportRenderingFieldsObj.mandatoryNullvalueBoolean.value = data.mandatoryNullvalueBoolean || false;
            colReportRenderingFieldsObj.mandatoryNullvalueText.value = data.mandatoryNullvalueText || '';
            info.data.colReportRenderingFields = JSON.stringify(colReportRenderingFieldsObj);
            info.data.reportRenderingLinkedIdList = reportRenderingLinkedIdList.map((i) => i.id);
        }

        // SurveyDefaults Fields
        if (info?.data?.colSurveyDefaultsFields) {
            let colSurveyDefaultsFieldsObj = JSON.parse(info.data.colSurveyDefaultsFields);
            colSurveyDefaultsFieldsObj.defaultSurveyQuestion.value = data.defaultSurveyQuestion || '';
            colSurveyDefaultsFieldsObj.defaultSurveyQualifier.value = data.defaultSurveyQualifier || '';
            info.data.colSurveyDefaultsFields = JSON.stringify(colSurveyDefaultsFieldsObj);
        }

        dispatchAction(dataMapUpdate(info.data));
        surveyStoreUpdate(info?.data);
    };

    return (
        <>
            {list?.content?.length > 0 && info?.data ? (
                <Col lg={9} sm={12} md={9} className="DataSchemaMappedFields">
                    <Card>
                        <Card.Body>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Col md={12} className="DataSchemaFieldDetails">
                                    {(info?.data?.colOvrtureDatabaseFieldName || info?.data?.colReadOnlyFields) && (
                                        <DatabaseField
                                            data={{
                                                errors,
                                                register,
                                                control,
                                                setValue,
                                                info: info?.data,
                                                setreportRenderingLinkedIdList,
                                                ...props,
                                            }}
                                        />
                                    )}
                                    {info?.data?.colNamingFields && (
                                        <NamingField
                                            data={{
                                                errors,
                                                register,
                                                control,
                                                setValue,
                                                info: info?.data,
                                                setreportRenderingLinkedIdList,
                                                ...props,
                                            }}
                                        />
                                    )}
                                    {info?.data?.colReportRenderingFields && (
                                        <ReportRendaringField
                                            data={{
                                                errors,
                                                register,
                                                control,
                                                setValue,
                                                info: info?.data,
                                                setreportRenderingLinkedIdList,
                                                ...props,
                                            }}
                                        />
                                    )}
                                    {info?.data?.colSurveyDefaultsFields && (
                                        <SurveyDefaultField
                                            data={{
                                                errors,
                                                register,
                                                control,
                                                setValue,
                                                info: info?.data,
                                                setreportRenderingLinkedIdList,
                                                showTitle: true,
                                                ...props,
                                            }}
                                        />
                                    )}
                                </Col>
                                <div className="button-list mt-3">
                                    <ButtonElement
                                        name="Apply"
                                        variant="primary"
                                        type="submit"
                                        className="action-btn"
                                        onClick={() => {
                                            setIsApplyAndClose(false);
                                        }}
                                    />
                                    <ButtonElement
                                        name="Apply & Close"
                                        variant="outline-primary"
                                        type="submit"
                                        onClick={() => {
                                            setIsApplyAndClose(true);
                                        }}
                                    />
                                    <ButtonElement
                                        name="Cancel"
                                        variant="danger"
                                        type="button"
                                        as="link"
                                        to={COMMON_PATH.SYSTEM_SETTINGS}
                                    />
                                </div>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            ) : (
                <Col lg={9} sm={12} md={9} className="DataSchemaFieldsNoData">
                    <Card>
                        <Card.Body>
                            <div className="text-center">
                                <h5>Select Data Fields From The Left-Hand Table.</h5>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            )}
        </>
    );
};

export default DataSchemaFieldDetails;
