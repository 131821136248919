// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { modalActions } from '../../../reducers/actions';
import { useDispatch } from 'react-redux';
import { useModalContext } from '../../../contexts/ModalContext';
import { userAdminPlatformBulkHardDelete, userAdminPlatformBulkSoftDelete } from '@ovrture/react-redux';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../components/form/input';
import { useParams } from 'react-router-dom';
const ManageAccess = (props) => {
    const { id, name } = useParams();
    const dispatch = useDispatch();
    const { modalDispatch } = useModalContext();
    /*
    form validation schema
    */
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        reset,
        setError,
        setFocus,
        // clearErrors,
        formState: { errors },
    } = useForm({});
    const onSubmit = (data) => {
        console.log('data', data);
    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <p className="m-0 p-0">How would you like recipients to access this survey?</p>

                <FormInput
                    type="radio"
                    value={1}
                    id="fkReportPreferenceId"
                    label="URL & Passcode Only"
                    className="col-md-2 d-inline-block mt-1 ms-1 pe-0 w-auto"
                    // checked={Number(values.fkReportPreferenceId) === Number(1)}
                    defaultChecked={true}
                    errors={errors}
                    register={register}
                />
                <span className="d-inline-block">
                    <ButtonElement
                        className="btn p-0 custom-tooltip-align"
                        icon={<i className="dripicons-information"></i>}
                        tooltipAlign="right"
                        tooltip="TBD."
                    />
                </span>
                <div className="button-list mb-0">
                    <ButtonElement
                        name="Reveal Passcode"
                        variant="primary"
                        type="button"
                        onClick={() => console.log('Reveal Passcode')}
                    />
                    <ButtonElement
                        name="Reset Passcode"
                        variant="outline-danger"
                        type="button"
                        //disabled={process.env.REACT_APP_MENU_DISABLE === 'true' ? true : false}
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) =>
                                        modalDispatch({
                                            type: modalActions.CLOSE_MODAL,
                                        }),
                                    modalVariant: 'primary',
                                    modalDataKey: ['systemAdmin', 'portfolioResetPasscode'],
                                    // data: { id },
                                },
                            })
                        }
                    />
                </div>
                <FormInput
                    type="radio"
                    value={2}
                    id="fkReportPreferenceId"
                    // checked={Number(values.fkReportPreferenceId) === Number(2)}
                    label="Magic Link Only"
                    className="col-md-2 d-inline-block ms-1 mb-2 pe-0 w-auto"
                    errors={errors}
                    register={register}
                />
                <span className="d-inline-block">
                    <ButtonElement
                        className="btn p-0 custom-tooltip-align"
                        icon={<i className="dripicons-information"></i>}
                        tooltipAlign="right"
                        tooltip="TBD."
                    />
                </span>
                <div className="modal-footer d-flex justify-content-center">
                    <ButtonElement
                        name="Proceed"
                        variant="primary"
                        type="button"
                        onClick={() => {
                            console.log('props.data', props.data);
                        }}
                    />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            })
                        }
                    />
                </div>
            </form>
        </>
    );
};

export default ManageAccess;
