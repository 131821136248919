import React, { useState, useReducer, useEffect } from 'react';
import { Col, Row, ListGroup, Dropdown, Card, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { StateFilterReducer, StatusFilterIntialState } from '../../../reducers/StatusFilterReducer';
import ButtonElement from '../../button/ButtonElement';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import HyperDatepicker from '../../datepicker/Datepicker';
import { dateTimeFormat } from '../../../utility';
import { FormInput } from '../../form/input';
import { getHistoryData, getRetainHistory, setHistoryData } from '../../../helpers/retainHistory';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { RedirectTo } from '../../../routes/role/path';
import { useCustomContext } from '../../../contexts/CustomContext';
import { customActions } from '../../../reducers/actions';
import { isArray } from 'underscore';
import {
    FISCAL_YEAR_FILTER_FIELD,
    clearGenericFilterData,
    getFilterStore,
    getFiscalYearStore,
    getReportTableFilterClearedStore,
    setGenericFilterData,
} from '../../../helpers/dataFiltering';
import moment from 'moment';

export const GenericFilter = (props) => {
    const [state, dispatch] = useReducer(StateFilterReducer, StatusFilterIntialState);
    const {
        register,
        handleSubmit,
        reset,
        control,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const { id } = useParams();
    let inputsVal = watch();
    const [status, setStatus] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const [dateLoad, setdateLoad] = useState(true);
    const [days, setDays] = useState(null);
    const [onload, setOnload] = useState(true);
    const [onloadForAutoClear, setOnloadForAutoClear] = useState(null);
    const [filterVal, setFilterVal] = useState(null);
    const [filterApply, setFilterApply] = useState(false);
    const [storeLoaded, setStoreLoaded] = useState(false);
    const [applyDisabled, setApplyDisabled] = useState(true);
    const { data, formElements, filterDispatch, customPaginationProps, tableId } = props;
    const { customDispatch, customState } = useCustomContext();
    let { fields, append, remove } = useFieldArray({
        control,
        name: 'tags',
    });
    const [isReset, setIsReset] = useState(false);

    useEffect(() => {
        try {
            let historyData = getRetainHistory(tableId) || getFilterStore(tableId);
            if (historyData && Object.keys(historyData)?.length !== 0) {
                setTimeout(() => {
                    setStoreLoaded(true);
                    Object?.entries(historyData)?.forEach((el) => {
                        formElements?.forEach((d) => {
                            if (d.type === 'select' && d.name === el[0] && el[1] !== '') {
                                let defaultVal = [];
                                if (d.isMulti && el[1] && el[1]?.length > 0) {
                                    el[1]?.forEach((val) => {
                                        defaultVal.push(
                                            d.options?.find((option) => {
                                                return option.value === val;
                                            })
                                        );
                                    });
                                } else {
                                    if (el[1]?.value) {
                                        if (FISCAL_YEAR_FILTER_FIELD.includes(el[0]))
                                            defaultVal.push({
                                                label: getFiscalYearStore(),
                                                value: getFiscalYearStore(),
                                            });
                                        else defaultVal.push(el[1]);
                                    } else {
                                        if (d?.options?.length > 0 && el[1]?.length > 0) {
                                            el[1]?.forEach((val) => {
                                                defaultVal.push(
                                                    d.options?.find((option) => {
                                                        return option.value === val;
                                                    })
                                                );
                                            });
                                        } else if (Array?.isArray(el[1])) {
                                            el[1]?.length > 0 && defaultVal.push({ label: el[1], value: el[1] });
                                        } else {
                                            defaultVal.push({ label: el[1], value: el[1] });
                                        }
                                    }
                                }
                                setTimeout(() => {
                                    setValue(el[0], defaultVal);
                                }, 200);
                            } else if (
                                d.type === 'date-picker' &&
                                (el[0] === 'from' || el[0] === 'to' || el[0] === 'startDate' || el[0] === 'endDate') &&
                                el[1]
                            ) {
                                setTimeout(() => {
                                    if (el[0] === 'startDate') {
                                        setValue('from', new Date(el[1]));
                                    } else if (el[0] === 'endDate') {
                                        setValue('to', new Date(el[1]));
                                    } else {
                                        setValue(el[0], new Date(el[1]));
                                    }
                                }, 500);
                            } else if (el[0] === 'dateRange' && d.name === 'dateRange') {
                                setValue(el[0], el[1]);
                                handleRadioChange(el[1], d);
                            } else {
                                setValue(el[0], el[1]);
                            }
                        });
                    });
                }, 2000);
            } else {
                let obj = formElements.find((f) => f.type === 'select' && f.label === 'Fiscal Year');
                const isReportCleared = getReportTableFilterClearedStore();
                let fiscalYear = obj?.isReportTable && isReportCleared ? null : getFiscalYearStore();
                if (fiscalYear && obj?.isYearClear) {
                    fiscalYear = getReportTableFilterClearedStore('systemAdminAnalyticsDashboardReportClear')
                        ? null
                        : fiscalYear;
                }

                if (obj?.name && fiscalYear) {
                    setValue(obj?.name, [{ label: fiscalYear, value: fiscalYear }]);
                }
            }
            setTimeout(() => onFilterApplied(true, historyData?.tags), 2000);
        } catch (e) {}
    }, []);

    useEffect(() => {
        if (showDropdown) {
            setTimeout(() => {
                let historyData = getRetainHistory(tableId) || getFilterStore(tableId);
                Object?.entries(historyData)?.forEach((el) => {
                    if (el[0] === 'dateRange' && el[1] === 'date') {
                        setValue(el[0], el[1]);
                        handleRadioChange(
                            el[1],
                            formElements.find((f) => f.name === 'dateRange')
                        );
                    }
                });
            }, 500);
        }
    }, [showDropdown]);

    const onSubmit = (formData) => {
        if (formData?.from) {
            formData.from = dateTimeFormat(formData.from, 'MM/DD/YYYY');
        }
        if (formData?.to) {
            formData.to = dateTimeFormat(formData.to, 'MM/DD/YYYY');
        }

        Object.entries(formData).forEach((data) => {
            if (Array.isArray(data[1])) {
                let elements = formElements.find((f) => f.name === data[0]);
                if (elements?.isMulti) {
                    const list = [];
                    data[1].forEach((el) => {
                        list.push(el?.value);
                    });
                    formData[data[0]] = list;
                } else if (elements) {
                    formData[data[0]] = data[1][0];
                }
            }
        });
        setGenericFilterData(tableId, formData);
        dispatch({
            type: 'ADD_FILTER',
            payload: {
                data: formData,
                filterDispatch: filterDispatch,
            },
        });

        try {
            let existingObj = getHistoryData() || {};
            customPaginationProps.page = 0;
            existingObj[tableId] = { ...customPaginationProps, ...formData };
            setHistoryData(JSON.stringify(existingObj));
        } catch (e) {}
        onFilterApplied();
        setShowDropdown(false);
        setdateLoad(false);
    };

    const setElementValue = () => {
        formElements?.forEach((ele) => {
            if (ele.type === 'date-picker') {
                setValue('from', null);
                setValue('to', null);
            }
        });
    };

    useEffect(() => {
        if (
            !window.location.pathname.includes('/soft-deleted-data-tenants') &&
            Number(id) &&
            onload &&
            filterVal === null &&
            Object.values(inputsVal)?.length > formElements?.length
        ) {
            if (Object.values(inputsVal)?.length > 0) {
                const cloneFood = JSON.parse(JSON.stringify(inputsVal));
                Object.values(cloneFood).forEach((inptVal) => {
                    if (Array.isArray(inptVal) && inptVal?.length > 0) {
                        let idIndex = inptVal?.findIndex((f) => f?.value === Number(id));
                        inptVal.forEach((item, index) => {
                            if (index !== idIndex) inptVal.splice(index, 1);
                        });
                    }
                });
                setOnload(false);
                setFilterVal(cloneFood);
            }
        }
        if (Object.values(inputsVal).length > 0) {
            let haveVals = true;
            Object.keys(inputsVal).forEach((val) => {
                if (Array.isArray(inputsVal[val])) {
                    if (!(val === 'days')) {
                        if (inputsVal[val]?.length > 0) {
                            haveVals = false;
                        }
                    }
                } else if (typeof inputsVal[val] === 'object') {
                    if (val === 'days' && (inputsVal[val]?.value === 0 || inputsVal[val]?.value === 'All Time')) {
                        haveVals = false;
                    } else if (
                        inputsVal[val]?.value > 0 ||
                        (inputsVal[val]?.label === 'PRIMARY' && inputsVal[val]?.value === 'PRIMARY')
                    ) {
                        haveVals = false;
                    }
                } else if (typeof inputsVal[val] === 'string' && val === 'dateRange') {
                    let historyData = getRetainHistory(tableId) || getFilterStore(tableId);
                    if (
                        !(
                            historyData[val] === inputsVal[val] &&
                            historyData['from'] === moment(inputsVal['from']).format('MM/DD/YYYY') &&
                            historyData['to'] === moment(inputsVal['to']).format('MM/DD/YYYY')
                        )
                    ) {
                        haveVals = false;
                    }
                }
            });
            if (_.isEqual(inputsVal, filterVal)) {
                setApplyDisabled(true);
            } else {
                setApplyDisabled(haveVals);
            }
            autoClearFilter(inputsVal);
        }
    }, [inputsVal]);

    useEffect(() => {
        if (isReset) {
            setApplyDisabled(false);
            setIsReset(false);
        }
    }, [isReset]);

    const autoClearFilter = (fields) => {
        if (onloadForAutoClear) {
            let num = 0;
            Object.keys(fields).map((k) => {
                const isArray = Array.isArray(fields[k]);
                if ((!isArray && fields[k] !== '') || (isArray && fields[k]?.length > 0)) {
                    num = num + 1;
                }
            });
            if (num === 0) setOnloadForAutoClear(false);
        }
    };

    useEffect(() => {
        if (onloadForAutoClear === false) clearFilter();
    }, [onloadForAutoClear]);

    // useEffect(() => {
    //     if (showDropdown === true) {
    //         filterVal &&
    //             Object?.keys(filterVal)?.length > 0 &&
    //             Object?.entries(filterVal)?.forEach((el) => {
    //                 setValue(el[0], el[1]);
    //             });
    //     }
    // }, [showDropdown]);

    const clearFilter = () => {
        let obj = null;
        try {
            let existingObj = getHistoryData() || {};
            obj = formElements.find((f) => f.type === 'select' && f.label === 'Fiscal Year');
            const fiscalYear = getFiscalYearStore();
            if (obj?.name && fiscalYear && !obj?.isReportTable) {
                customPaginationProps[obj?.name] = { label: fiscalYear, value: fiscalYear };
            }
            customPaginationProps.page = 0;
            existingObj[tableId] = { ...customPaginationProps };
            setHistoryData(JSON.stringify(existingObj));
        } catch (e) {}

        let defaultData = clearGenericFilterData(formElements, tableId);
        if (defaultData && !obj?.isReportTable) {
            dispatch({
                type: 'ADD_FILTER',
                payload: {
                    data: defaultData,
                    filterDispatch: filterDispatch,
                },
            });
        } else {
            dispatch({
                type: 'CLEAR_FILTER',
                payload: {
                    data: data,
                    status: status,
                    filterDispatch: filterDispatch,
                },
            });
        }
        setElementValue();
        setStatus({});
        reset();
        fields = [];
        formElements?.forEach((ele) => {
            if (ele.type === 'date-picker') {
                setValue('from', '');
                setValue('to', '');
            } else if (ele.type === 'tag') {
                remove();
            } else if (ele?.skipToClear && ele?.setYearOnClear) {
                const fiscalYear = getFiscalYearStore();
                setTimeout(() => {
                    setValue(ele?.name, [{ label: fiscalYear, value: fiscalYear }]);
                }, 200);
            } else if (ele?.name === 'days' && ele?.defaultValue?.length > 0) {
                setValue(ele?.name, ele?.defaultValue);
            } else if (!ele?.skipToClear) {
                setValue(ele?.name, '');
            }
        });
        onFilterApplied(null, null, true);
        setIsReset(true);
        setShowDropdown(false);
        setdateLoad(false);

        let obj_ = formElements.find((f) => f.type === 'select' && f.label === 'Fiscal Year');
        const fiscalYear = getFiscalYearStore();
        if (obj_?.name && fiscalYear && !obj_?.isReportTable) {
            setValue(obj_?.name, [{ label: fiscalYear, value: fiscalYear }]);
        }
    };

    const onFilterApplied = (isOnload, fieldsHistory, isClearFilter) => {
        let flag = false;
        for (let index = 0; index < formElements.length; index++) {
            const ele = formElements[index];
            if (ele.type === 'date-picker') {
                if (ele.fromDefaultValue || ele.toDefaultValue) {
                    flag = true;
                    break;
                } else if (getValues('from') || getValues('to')) {
                    flag = true;
                    break;
                }
            } else {
                if (isOnload) {
                    if (ele?.defaultValue?.length > 0 && ele?.defaultValue[0]) {
                        flag = true;
                        break;
                    } else if (ele?.defaultValue?.value) {
                        flag = true;
                        break;
                    } else if (ele?.name === 'colTags' && (fields?.length > 0 || fieldsHistory?.length > 0)) {
                        flag = true;
                    } else if (getValues(ele?.name)) {
                        let newEle = getValues(ele?.name);
                        let toggle = ele?.options?.map((items) => items.value);
                        if ((newEle?.length > 0 && newEle[0] && !newEle[0]) || newEle?.length > 0) {
                            flag = true;
                            break;
                        } else if (ele?.name === 'colIsActive' && newEle?.length > 0 && !toggle[1]) {
                            flag = true;
                            break;
                        } else if (newEle?.value || newEle[0] === 0 || newEle[0]) {
                            flag = true;
                            break;
                        }
                    }
                } else {
                    if (ele?.name === 'colTags' && (fields?.length > 0 || fieldsHistory?.length > 0)) {
                        flag = true;
                    } else if (getValues(ele?.name)) {
                        let newEle = getValues(ele?.name);
                        if (window.location.pathname === RedirectTo('/reports-create')) {
                            const yearLst = formElements[1];
                            if (!getValues(yearLst.name)) {
                                flag = false;
                                break;
                            }
                        }
                        if (newEle?.length > 0 && newEle[0]) {
                            flag = true;
                            break;
                        } else if (newEle?.value) {
                            flag = true;
                            break;
                        }
                    }
                }
            }
        }
        if (formElements?.find((f) => f?.skipToClear === true)) {
            setFilterApply(true);
        } else {
            setFilterApply(flag);
        }

        if (isOnload) {
            if (!Number(id)) setFilterVal(inputsVal);
        } else if (isClearFilter) {
            setFilterVal({});
        } else {
            setFilterVal(inputsVal);
        }
    };

    useEffect(() => {
        dispatch({
            type: 'FETCH_FILTER',
            payload: {
                data: data,
                status: status,
                filterDispatch: filterDispatch,
            },
        });
    }, []);
    useEffect(() => {
        dispatch({
            type: 'GET_STATUS',
            payload: {
                setStatus: setStatus,
                status: status,
            },
        });
    }, [status]);

    const handleRadioChange = (value, item) => {
        if (typeof item.handleChange === 'function') {
            item.handleChange(item.name, value);
        }
    };

    const handleTagInput = (e) => {
        if (e.key === 'Enter' || e.keyCode === 9) {
            e.preventDefault();
            if (e.target.value.length > 0) tagValidation(e);
        }
    };
    const tagValidation = (e) => {
        if (e.target.value.length > 0) {
            append({ [e.target.name]: e.target.value });
            setValue(e.target.name, '');
        }
    };
    const [fiscalYear, setFiscalYear] = useState(null);
    const changeDate = (val) => {
        setDays(val?.value);
        let from = '';
        let to = '';
        if (val?.value) {
            const date = new Date();
            from = date.setDate(date.getDate() - val?.value);
            to = new Date();
        }
        formElements?.forEach((ele) => {
            if (ele.type === 'date-picker' && ele.fromEvent && ele.toEvent) {
                setTimeout(() => {
                    setValue('from', from || '');
                    setValue('to', to || '');
                }, 100);
            }
        });
        setFiscalYear(val);
    };

    const checkData = (item, field) => {
        try {
            if (customState?.colReportYear && item.name === 'colReportYear') {
                return { label: customState?.colReportYear, year: customState?.colReportYear };
            } else return field.value;
        } catch (error) {}
    };

    const onChangeColReport = (val, item) => {
        try {
            if (item.name === 'colReportYear' && window.location.pathname.includes('reportdata') && val) {
                let ele = document.querySelector('.react-select__single-value');
                if (ele) {
                    ele.innerText = val?.value;
                }
            }
        } catch (error) {}
    };

    const _renderElements = (item) => {
        let element;
        switch (item.type) {
            case 'select':
                element = item?.isInputEditable ? (
                    <Col xs="12">
                        <Form.Label>{item.label}</Form.Label>
                        <Controller
                            control={control}
                            name={item.name}
                            defaultValue={item.defaultValue ? item.defaultValue : ''}
                            render={({ field }) => (
                                <Select
                                    isDisabled={item?.isDisabled ? item?.isDisabled : false}
                                    name={item?.name}
                                    onChange={(val) => {
                                        field.onChange(val);
                                        changeDate(val);
                                        setOnloadForAutoClear(true);
                                    }}
                                    isMulti={item?.isMulti}
                                    options={item.options}
                                    className={item?.setActive ? 'fw-bolder react-select' : 'react-select'}
                                    classNamePrefix={item?.setActive ? 'fw-bolder react-select' : 'react-select'}
                                    value={field.value}
                                    defaultValue={item.defaultValue ? item.defaultValue : ''}
                                    placeholder={item?.multiSelectPlaceholder}
                                />
                            )}
                        />
                    </Col>
                ) : (
                    <Col xs="12">
                        <Form.Label>{item.label}</Form.Label>
                        <Controller
                            control={control}
                            name={item.name}
                            defaultValue={item.defaultValue ? item.defaultValue : ''}
                            render={({ field }) => (
                                <Select
                                    isDisabled={item?.isDisabled ? item?.isDisabled : false}
                                    name={item?.name}
                                    onChange={(val) => {
                                        field.onChange(val);
                                        item?.error && (item.error = null);
                                        setOnloadForAutoClear(true);
                                        onChangeColReport(val, item);
                                    }}
                                    isMulti={item?.isMulti}
                                    options={item.options}
                                    className={item?.setActive ? 'fw-bolder react-select' : 'react-select'}
                                    classNamePrefix={item?.setActive ? 'fw-bolder react-select' : 'react-select'}
                                    value={checkData(item, field)}
                                    defaultValue={item.defaultValue ? item.defaultValue : ''}
                                    placeholder={item?.multiSelectPlaceholder}
                                />
                            )}
                        />
                        {item?.error && (
                            <Form.Control.Feedback type="invalid" className="custom-invalid">
                                {item.error}
                            </Form.Control.Feedback>
                        )}
                    </Col>
                );
                break;
            case 'range':
                element = (
                    <Col xs="12">
                        <Form.Group size="sm">
                            <Form.Label className="d-block">
                                <b>{item?.label}</b>
                            </Form.Label>
                            <Form.Label className="mb-1">{item?.maxLabel}</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={item?.maxLabel}
                                id={item?.max ? item?.max : 'max'}
                                name={item?.max ? item?.max : 'max'}
                                className={errors?.[item?.maxLabel] ? 'is-invalid' : ''}
                                {...register(item?.max ? item?.max : 'max', { required: item?.required })}
                            />
                            <Form.Label className="mb-1 mt-2">{item?.minLabel}</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder={item?.minLabel}
                                id={item?.min ? item?.min : 'min'}
                                name={item?.min ? item?.min : 'min'}
                                className={errors?.[item?.minLabel] ? 'is-invalid' : ''}
                                {...register(item?.min ? item?.min : 'min', { required: item?.required })}
                            />
                        </Form.Group>
                    </Col>
                );
                break;
            case 'date-picker':
                element = (
                    <>
                        <Col
                            xs={6}
                            className={
                                item?.disabled ? 'disabled from-date' : days === 0 ? 'disabled from-date' : 'from-date'
                            }>
                            <Form.Label>{item?.fromLabel ? item?.fromLabel : 'From'}</Form.Label>
                            <Controller
                                control={control}
                                name="from"
                                defaultValue={new Date()}
                                render={({ field }) => {
                                    let defaultDate = item?.fromDefaultValue ? item?.fromDefaultValue : new Date();
                                    return (
                                        <>
                                            <HyperDatepicker
                                                disabled={item?.disabled}
                                                value={field?.value ? new Date(field?.value) : defaultDate}
                                                inputClass="form-control-light"
                                                hideAddon={true}
                                                onChange={(startDate) => {
                                                    field.onChange(startDate);
                                                    setOnloadForAutoClear(true);
                                                }}
                                                maxDate={getValues('to')}
                                            />
                                        </>
                                    );
                                }}
                            />
                        </Col>
                        <Col
                            xs={6}
                            className={
                                item?.disabled ? 'disabled to-date' : days === 0 ? 'disabled from-date' : 'to-date'
                            }>
                            <Form.Label>{item?.toLabel ? item?.toLabel : 'To'}</Form.Label>
                            <Controller
                                control={control}
                                name="to"
                                defaultValue={new Date()}
                                render={({ field }) => {
                                    let defaultDate = item?.toDefaultValue ? item?.toDefaultValue : new Date();
                                    return (
                                        <HyperDatepicker
                                            disabled={item?.disabled}
                                            value={field?.value ? new Date(field?.value) : defaultDate}
                                            inputClass="form-control-light"
                                            hideAddon={true}
                                            onChange={(endDate) => {
                                                field.onChange(endDate);
                                                setOnloadForAutoClear(true);
                                            }}
                                            minDate={getValues('from')}
                                        />
                                    );
                                }}
                            />
                        </Col>
                    </>
                );
                break;
            case 'radio':
                element = (
                    <>
                        <label>{item?.label}</label>
                        {item?.options?.map((option, index) => {
                            return (
                                <Col md={12} key={index}>
                                    <FormInput
                                        className="col-md-12 mt-1"
                                        type="radio"
                                        id={item?.name}
                                        name={item?.name}
                                        label={option?.key}
                                        value={option?.value}
                                        defaultChecked={item?.defaultValue === option.value}
                                        register={register}
                                        onChange={(e) => {
                                            setValue(item?.name, e.target.value);
                                            handleRadioChange(e.target.value, item);
                                            setOnloadForAutoClear(true);
                                            setdateLoad(false);
                                        }}
                                    />
                                </Col>
                            );
                        })}
                    </>
                );
                break;
            case 'tag':
                element = (
                    <div className="filter-tag mb-3">
                        <label className="d-block mb-1">{item?.label}</label>
                        <Col md={12}>
                            <div className="custom-filter-style-block">
                                <div className="inner-wrapper">
                                    {fields?.map((d, i) => {
                                        return (
                                            <span className="list" key={i}>
                                                <span className="value">{d[item.name]}</span>
                                                <span
                                                    className="cross-icon"
                                                    onClick={() => {
                                                        remove(i);
                                                        if (fields.length - 1 === 0) setOnloadForAutoClear(true);
                                                    }}>
                                                    <svg
                                                        height="14"
                                                        width="14"
                                                        viewBox="0 0 20 20"
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        className="css-tj5bde-Svg">
                                                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                        );
                                    })}
                                </div>
                                <FormInput
                                    type="text"
                                    id={item.name}
                                    name={item?.name}
                                    {...register(item?.name)}
                                    error="false"
                                    className="custom-input"
                                    floatinglabel="false"
                                    onKeyDown={(e) => {
                                        handleTagInput(e);
                                    }}
                                    onBlur={(e) => tagValidation(e)}
                                />
                            </div>
                        </Col>
                    </div>
                );
                break;
            default:
                element = (
                    <Col xs="12">
                        <Form.Group size="sm">
                            <Form.Label>{item?.label}</Form.Label>
                            <Form.Control
                                type={item?.type}
                                placeholder={item?.label}
                                id={item?.name}
                                name={item?.name}
                                className={errors?.[item?.name] ? 'is-invalid' : ''}
                                {...register(item?.name, { required: item?.required })}
                            />
                            {errors?.[item?.name] && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.[item?.name]?.type}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                );
                break;
        }
        return element;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Dropdown
                align="right"
                className={filterApply ? `dropdown-card filter-wrapper filter-applied` : `dropdown-card filter-wrapper`}
                show={showDropdown}
                onToggle={setShowDropdown}>
                <Dropdown.Toggle id="dropdown-autoclose-outside" bsPrefix="btn-sm btn-default btn-filter shadow-none">
                    <i className={filterApply ? `mdi mdi-filter-menu` : `mdi mdi-filter-menu-outline`}></i>
                    Filter
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Card.Header>
                        <Row className="justify-content-between align-items-center">
                            <Col xs="auto">
                                <h4 className="card-header-title">Filters</h4>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    onClick={clearFilter}
                                    variant="link"
                                    className="ml-auto p-0 custom-button-shadow">
                                    Clear
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <ListGroup className="list-group-flush mt-n3 mb-3">
                            <ListGroup.Item className="pb-0">
                                {isArray(formElements) &&
                                    formElements?.map((item, index) => {
                                        if (item?.isHide) return null;
                                        return (
                                            <Row
                                                key={index}
                                                className={
                                                    item.type === 'tag' || item?.error
                                                        ? ''
                                                        : item.isDisabled === true
                                                        ? 'disabled filter-option-disable mb-3'
                                                        : item?.isHidden === true || item?.isHidden === 'true'
                                                        ? 'hide-filter-option'
                                                        : 'mb-3'
                                                }>
                                                {_renderElements(item)}
                                            </Row>
                                        );
                                    })}
                            </ListGroup.Item>
                        </ListGroup>
                        <ButtonElement
                            name="Apply"
                            variant="primary"
                            className=" w-100 custom-button-shadow"
                            type="submit"
                            disabled={filterApply && applyDisabled ? true : applyDisabled}
                        />
                    </Card.Body>
                </Dropdown.Menu>
            </Dropdown>
        </form>
    );
};
