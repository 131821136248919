// @flow
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Card } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import {
    templateFileDownload,
    templateFileReset,
    validateRecordGivings,
    updateRecordGivings,
    downloadRecordGivingErrorSheet,
    recordImportReset,
    useNotification,
} from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { downloadFile } from '../../../helpers/downloadFile/downloadFile';
import { COMMON_PATH } from '../../../routes/role/path';
import { useHistory } from 'react-router-dom';
import { fileValidation } from '../../../helpers/autoSubmit/commonFunctions';
import { addToSessionStorage } from '../../../helpers/editor/migration/TenantMigration';

const UploadNewRecipients = (props) => {
    const [toggle, setToggle] = useState(false);
    const [file, setFile] = useState(null);
    const [customError, setCustomError] = useState(null);
    const { modalDispatch } = useModalContext();
    const [isImport, setIsImport] = useState(false);
    const [isPreflight, setIsPreflight] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const { Toast } = useNotification();
    const dispatch = useDispatch();
    const history = useHistory();
    const { exportData, xlsxData, assetImpactErrorDownload, loadingList, success } = useSelector((state) => ({
        exportData: state?.templateFile?.download,
        xlsxData: state?.socket?.xlsx,
        loadingList: state?.socket?.loadingList,
        assetImpactErrorDownload: state?.recordImport?.downloadRecordGivingErrorSheet,
        success: state?.socket?.success,
    }));

    useEffect(() => {
        if (exportData) {
            downloadFile(
                exportData?.data,
                'application/zip',
                exportData?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(templateFileReset());
        }
        if (xlsxData) {
            if (isPreflight) {
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalVariant: 'primary',
                        modalDataKey: ['common', 'preflightData'],
                        data: {
                            file: file,
                            data: xlsxData,
                            KeyName: 'importdetail',
                            handleImport: handleImport,
                            handleDownloadError: handleDownloadError,
                            resetForm: resetForm,
                            isUpdater: false,
                        },
                    },
                });
            }
            if (isImport) {
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalVariant: 'primary',
                        modalDataKey: ['common', 'importData'],
                        data: {
                            file: file,
                            data: xlsxData,
                            resetForm: resetForm,
                            KeyName: 'importdetail',
                            handleImport: handleImport,
                            handleDownloadError: handleDownloadError,
                            isUpdater: false,
                            onRedirect: onRedirect,
                        },
                    },
                });
            }
        } else if (success && xlsxData === null) {
            setTimeout(() => {
                resetForm();
            }, 1000);
        }
        // if (assetImpactErrorDownload) {
        //     downloadFile(
        //         assetImpactErrorDownload?.data,
        //         'application/zip',
        //         assetImpactErrorDownload?.headers['content-disposition'].split('filename=')[1]
        //     );
        //     dispatch(recordImportReset());
        // }
        //eslint-disable-next-line
    }, [exportData, xlsxData, assetImpactErrorDownload, success]);
    const onRedirect = () => {
        history.push(COMMON_PATH.RECORD_PORTFOLIO);
    };
    useEffect(() => {
        const count = loadingList?.filter((loading) => {
            return (
                loading?.message === 'Preflighting record givings.' || loading?.message === 'Uploading record givings.'
            );
        });
        setTimeout(() => {
            setDisabled(count?.length > 0);
            sessionStorage.setItem('isProcessing', count?.length > 0);
        }, 100);
    }, [loadingList]);

    const handleShowNotification = () => {
        return Toast.warning({
            description:
                'An preflight/import is currently in progress. once this is complete, you will be able to request a new preflight/import.',
        });
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    const downloadTemplate = () => {
        dispatch(templateFileDownload({ key: 'record-giving', isUpdater: false }));
    };

    const handleDownloadError = (payload) => {
        dispatch(downloadRecordGivingErrorSheet({ ...payload }));
    };

    const handlePreflight = () => {
        try {
            if (fileValidation(file, 'chooseFile', setCustomError)) {
                if (disabled && JSON.parse(sessionStorage.getItem('isProcessing'))) {
                    handleShowNotification();
                    return null;
                }
                const formData = new FormData();
                formData.append('requestFile', file);
                const payload = {
                    params: { isUpdater: false },
                    data: formData,
                };
                setIsPreflight(true);
                setIsImport(false);
                dispatch(validateRecordGivings(formData));
                let obj = {
                    page: 'ImportGivingData',
                    allDispacthes: ['downloadRecordGivingErrorSheet'],
                    isUpdater: false,
                };
                addToSessionStorage(obj);
            }
        } catch (error) {}
    };

    const handleImport = () => {
        try {
            if (fileValidation(file, 'chooseFile', setCustomError)) {
                if (disabled && JSON.parse(sessionStorage.getItem('isProcessing'))) {
                    handleShowNotification();
                    return null;
                }
                const formData = new FormData();
                formData.append('requestFile', file);
                setIsPreflight(false);
                setIsImport(true);
                dispatch(updateRecordGivings(formData));
                let obj = {
                    page: 'ImportGivingData',
                    allDispacthes: ['downloadRecordGivingErrorSheet'],
                    isUpdater: false,
                };
                addToSessionStorage(obj);
            }
        } catch (error) {}
    };

    const resetForm = () => {
        setToggle(false);
        setFile(null);
        document.getElementById('importForm')?.reset();
    };
    return (
        <>
            {/* <p>Use this module to import record-based giving data.</p> */}
            <Card>
                <Card.Body>
                    <Col>
                        <h5 className="text-primary">STEP 1:</h5>
                        <p>Download template:</p>
                        <ButtonElement
                            name="Download Excel Template"
                            variant="primary"
                            type="button"
                            onClick={() => downloadTemplate()}
                        />
                    </Col>
                    <Col className="mt-3">
                        <h5 className="text-primary">STEP 2:</h5>
                        <p>Populate all required and relevant fields with your records data.</p>
                    </Col>
                    <Col md={10} className="mt-3">
                        <h5 className="text-primary">STEP 3:</h5>
                        <p>Locate, select and upload the completed spreadsheet file:</p>
                        <form id="importForm">
                            <FormInput
                                type="file"
                                id="chooseFile"
                                errors={customError}
                                accept=".xlsx, .xls"
                                register={register}
                                onChange={(e) => {
                                    setCustomError(null);
                                    setFile(e.target.files[0]);
                                    setToggle(e.target.value === '' ? false : true);
                                }}
                            />
                            <div className="button-list pt-3">
                                <ButtonElement
                                    name="Preflight Data"
                                    variant="secondary"
                                    onClick={handlePreflight}
                                    //disabled={toggle ? false : true}
                                />
                                <ButtonElement
                                    name="Upload"
                                    variant="primary"
                                    type="button"
                                    //disabled={toggle ? false : true}
                                    onClick={handleImport}
                                />
                            </div>
                        </form>
                    </Col>
                </Card.Body>
            </Card>
        </>
    );
};

export default UploadNewRecipients;
